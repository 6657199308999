.highcharts-navigator-mask-inside {
    stroke-width: 2px;
    stroke: rgba(var(--palette-primary-dark-rgb), 0.25);
    rx: 8px;
    height: 40px !important;
    transform: translate(0, -7px);
}
.highcharts-navigator-mask-outside {
    stroke-width: 0px;
    stroke: #cccccc;
    height: 24px !important;
}
.highcharts-navigator-series rect {
    height: 24px !important;
}
.highcharts-navigator-outline {
    display: none;
}
.highcharts-navigator {
    x: 100px;
}
.highcharts-navigator-series rect {
    stroke-width: 0;
    transform: translate(0, -20px);

}
.highcharts-plot-background {
    rx: 5px;
    fill: rgba(255, 255, 255, 1);
}
.highcharts-axis-line {
    display: none;
}
.highcharts-tick {
    stroke: #D7DCE2;
}
.weather-alert-bar span {
    display: block;
    top: 5px !important;
}

.highcharts-container {
    flex-grow: 1;
}

.arrow-buttons-container {
    width: 100%;
    pointer-events: none;
    position: relative;
    bottom: 62px;
    height: 0;
    padding-left: 40px;
    padding-right: 40px;
    justify-content: space-between;
}