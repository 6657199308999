#timeline-panel {
    flex-grow: 1;
}

#timeline-panel .period-select  .MuiSelect-select:before {
    content: "Period: ";
    padding-right: 5px;
    display: inline-block;
    color: #677A8E;
}
#timeline-panel .timeline-period-select .MuiSelect-select:focus {
    background-color: #ffffff;
}
#timeline-panel .timeline-period-select .MuiSelect-select {
    padding-left: 0;
    padding-right: 18px;
    font-size: 14px;
    color: #171E27;
}
#timeline-panel .timeline-period-select svg {
    color: #677A8E;
    width: 20px;
    margin-right: 6px;
}
#timeline-panel .timeline-period-select {
    width: 136px;
    padding: 8px 10px;
    border: 1px solid #f0f2f5;
}

#timeline-panel .container {
    flex-grow: 1;
    height: 100%;
    width: 100%;
    border-radius: 16px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: white;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 4px 0 14px 0 rgba(103, 122, 142, 0.12);
}

.MuiList-root .MuiMenuItem-gutters {
    color: #171E27;
    font-size: 14px;
    line-height: 24px;
    padding: 3px 5px;
    width: 100%;
    border-radius: 8px;
}
#timerange-menu .MuiMenuItem-gutters:hover, .timeline-elipsis-menu-item:hover {
    background-color: #f8faff;
}
#timerange-menu .Mui-selected {
    background-color: #ffffff;
    color: #09638C;
}
#timeline-panel .MuiMenu-paper {
    transition: none !important;
}

.timeline-modal, .timeline-modal {
    border: 1px solid #f0f2f5;
    border-radius: 8px;
}

#timeline-panel .timeline-shadow, .timeline-shadow {
    box-shadow: 0px 1px 3px rgba(103, 122, 142, 0.24);
    border-radius: 8px;
    border: 0;
}
.timeline-modal .MuiPaper-root {
    padding: 24px;
    border: 1px solid #F0F2F5;
    box-shadow: 0px 4px 12px rgba(103, 122, 142, 0.24);
    border-radius: 12px;
    font-size: 14px;
}
.timeline-modal .MuiInputBase-root {
    padding: 0 10px;
    margin: 0;
    border: 1px solid #F0F2F5;
    font-size: 14px;
}
.timeline-modal .MuiIconButton-root {
    width: 32px;
    height: 32px;
}
.modal-header {
    font-weight: 500;
    font-size: 24px;
    color: var(--palette-grey-900);
}
.modal-section-name {
    color: var(--palette-grey-500);
    line-height: 20px;
}
.timeline-modal .MuiCheckbox-root {
    padding: 0;
}
.timeline-modal .MuiCheckbox-root svg {
    padding: 0;
}
.modal-checkbox {
    display: flex;
    width: 16px;
    height: 16px;
    border: 1px solid #09638C;
    border-radius: 4px;
    justify-content: center;
    align-content: center;
}
.modal-checkbox img {
    width: 9px;
    vertical-align: middle;
}
.modal-checkbox-root {
    line-height: 16px;
    padding: 5px 3px;
}
.modal-checkbox-root div {
    margin-left: 5px;
}
.modal-buttons .MuiButton-root {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
}
.modal-buttons .timeline-shadow {
    margin-left: 10px;
}

.modal-buttons-border {
    border-top: 1px solid #f0f2f5;
}
.modal-buttons .timeline-delete {
    background-color: #FEEEEE;
    box-shadow: 0px 1px 3px rgba(168, 59, 59, 0.24);
    color: #A83B3B;
    margin-left: 10px;
}
.modal-buttons .timeline-cancel {
    margin-left: 0px;
}
.modal-delete-title {
    color: #171E27;
}
.modal-delete-text {
    color: #333;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.modal__close-button {
    margin-left: auto !important;
    padding: 4px;
    border-radius: 8px;
    border: 1px solid  #F0F2F5;
    background: #FFF;
    box-shadow: 0px 1px 3px 0px rgba(103, 122, 142, 0.24);
}