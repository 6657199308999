.wrapper{
  width: 520px !important;

  .title{
    color: var(--palette-grey-900);
  }

  .subTitle{
    color: var(--palette-grey-500);
  }
}