html {
    -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
    overflow: hidden;
}
.group:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
textarea, input {
    -webkit-touch-callout: text !important;
    -webkit-user-select: text !important;
    -khtml-user-select: text !important;
    -moz-user-select: text !important;
    -ms-user-select: text !important;
    user-select: text !important;
}

* {
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

html, body, .wrapper {
    height: 100%;
    width: 100%;
}

.wrapper {
    float: left;
    width: 100%;
    overflow: auto;
    position: relative;
    overflow: hidden;
}

#map {
    width: 100%;
    height: 100%;
}

/* from default theme style.css */

body ins{
    background: none;
}

.nhctimelabel{
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 5px 10px;
    cursor: pointer;
}
.nhctimelabel.model::after,
.nhctimelabel.nhc::after{
    content: '+';
    display: inline-block;
    width: 20px;
    text-align: center;
}
.nhctimelabel.model.active::after{
    content: '-';
    display: inline-block;
    width: 20px;
    text-align: center;
}
.nhctimelabel.model.maplibregl-marker-anchor-left,
.nhctimelabel.model.mapboxgl-marker-anchor-left{
    border-left: 5px solid rgb(255, 171, 16);
}
.nhctimelabel.model.maplibregl-marker-anchor-right,
.nhctimelabel.model.mapboxgl-marker-anchor-right{
    border-right: 5px solid rgb(255, 171, 16);
}
.nhctimelabel.nhc.maplibregl-marker-anchor-left,
.nhctimelabel.nhc.mapboxgl-marker-anchor-left{
    border-left: 5px solid #2D71AD;
}
.nhctimelabel.nhc.maplibregl-marker-anchor-right,
.nhctimelabel.nhc.mapboxgl-marker-anchor-right{
    border-right: 5px solid #2D71AD;
}
.nhctimelabel span:last-child{
    width: 20px;
    text-align: center;
}

.mapboxgl-map .mapboxgl-ctrl-bottom-right{
    pointer-events: all;
}


.mapboxgl-map .mapboxgl-ctrl-attrib{
    display: none;
}

*:focus{
    box-shadow: 0 0 6px var(--semitransparentheavycolor) inset;
}

