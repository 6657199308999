.header{
  border-bottom: 1px solid var(--palette-grey-100);

  .select{
    color: var(--palette-grey-400);
  }
}

.deleteUserGroupTitle{
  font-weight: 500;
  color: var(--palette-grey-900);
}