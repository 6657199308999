.square {
  width: 50px;
  height: 50px;
}

.bst-marker {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .impmarker-header {
    gap: 8px;
    white-space: initial;
    padding-right: 14px;
    border-bottom: 1px solid var(--palette-grey-200);
    display: flex;
    align-items: center;

    .impmarker-header-icon{
      padding: 0 5px;
      border-right: 1px solid var(--palette-grey-200);
      background-image: url("../../../../shared/assets/svg/storm_track_icon.svg");
      background-repeat: no-repeat;
      width: 28px;
      height: 28px;
    }
  }

  .impmarker-body {
    width: 100%;

    .row {
      display: flex;
      align-items: start;
      justify-content: space-between;
    }

    .column {
      gap: 0;
    }
  }

  hr {
    margin: 4px 0;
  }
}


