.headerWrapper{
  border-bottom: 1px solid var(--palette-grey-100);

  .header{
    line-height: 45px;
  }
}

.preferencesCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
  height: max-content;
  padding: 12px 16px;
  border-radius: 8px;
  border: var(--palette-grey-100);
  background: var(--palette-grey-background);
}