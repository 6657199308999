.weatherDefinitionLine{
  display: flex;
  cursor: pointer;
  height: 40px;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--palette-grey-100);
}

.agreeModalText{
  font-weight: 500;
  color: var(--palette-grey-900);
}