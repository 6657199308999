.actionItemsContentWrapper{
    border: 1px solid var(--palette-grey-100);
    border-radius: 12px;
    padding: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.newActionItemTitlesWrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.newActionItemTitles{
    font-weight: 400;
    font-size: 14px;
    color: var(--palette-grey-600);
}

.actionItemsTitles{
    font-weight: 500;
    font-size: 12px;
    color: var(--palette-grey-600);
    text-transform: uppercase;
}

.actionItemsRadio{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.actionItemsRadioTitle{
   font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--palette-grey-900);
}