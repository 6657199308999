.wrapper{
  display: flex;
  flex-direction: row;
  padding:6px 12px;
  gap: 4px;
  background: white;
  border-radius: 8px;
  border: 1px solid var(--palette-grey-100);
  align-items: center;
  box-shadow: 0 1px 3px 0 #677A8E3D;

  .locationItem{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    color: var(--palette-grey-900);

    .titleWrapper{
      display:flex;
      align-items:start;
      gap:8px;

      .title{
        font-weight: 500;
        font-size: 14px;
        word-break: break-all;
      }
    }
    .group{
      font-weight: 400;
      font-size: 14px;
      color: var(--palette-grey-500);
      word-break: break-all;
      margin-left:24px;
    }
  }

  .buttonsWrapper{
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;

    .button{
      box-shadow: 0 1px 3px 0 #677A8E3D;
    }
  }
}