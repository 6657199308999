.toolbarBorder {
  border-bottom: 1px solid var(--palette-grey-100);
}

.wrapper {
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-Y: auto;

  .sectionWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .section {
      font-weight: 400;
      font-size: 14px;
      color: var(--palette-grey-600);
    }

    .content {
      display: flex;
      flex-direction: column;
      width: fit-content;
      gap: 16px;
      max-height: 220px;
      padding-right: 20px;
      overflow-y: auto;
      min-width: 400px;

      .categoryContent {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        .categoryNameWrapper {
          font-size: 16px;
          color: var(--palette-grey-900);
          align-items: center;
          display: flex;
          gap: 4px;
          line-height: 24px;
        }
      }

      .iconButton {
        width: 32px;
        height: 32px;
        box-shadow: 0 1px 3px 0 #677A8E3D;
      }
    }
  }

  .wdSectionWrapper {
    display: flex;
    gap: 16px;

    .wdSection {
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 600px;

      .productsList {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        max-height: 400px;
        height: 0;
        background-color: white;
        border-radius: 12px;
        width: 100%;
        padding: 0;

        .listTitle{
          font-size: 12px;
          font-weight: 400;
          color: var(--palette-grey-400);
        }

        .wdItem{
          font-size: 14px;
          font-weight: 400;
          line-height: 30px;
          cursor: pointer;
        }
      }

      .isOpenSearching{
        height: fit-content;
        padding: 12px;
      }
    }
  }
  .AddButton {
    font-size: 14px;
    font-weight: 500;
    width: fit-content;
  }
}

