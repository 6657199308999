.NWAPanel .toolbar.padding {
    padding: 0 0 16px 0;
}
.NWAPanel {
    border-radius: 16px;
}

@media (max-height: 815px) {
    .NWAPanel {
        border-radius: 16px 16px 16px 0;
    }
}