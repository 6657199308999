.iconOnly {
    width: 40px;
}

.iconOnly .MuiOutlinedInput-input,
.iconOnly .MuiInputBase-root {
    padding: 0;
}

.iconOnly input {
    width: 0;
}

.iconOnly .MuiButtonBase-root,
.iconOnly .MuiInputAdornment-root {
    margin: 0;
    border-radius: 8px;
}
