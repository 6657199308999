.wrapper{
  width: 520px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  .contentWrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    .headerWrapper{
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
    }

    .containerWrapper{
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .container{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .selectedFileWrapper{
          display: flex;
          width: 100%;
          height: 48px;
          align-items: center;
          padding: 0 16px;
          border-radius: 8px;
          justify-content: space-between;

          .selectedFile{
            display: flex;
            gap: 4px;
            width: 440px;
            overflow: hidden;
            white-space: nowrap;

            .selectedFileTitle{
              color: var(--palette-grey-900);
              font-weight: 500;
              font-size: 16px;
            }
          }
        }

        .wrapperArea{
          width: 100%;
          height: 130px;
          display: flex;
          flex-direction: column;
          gap: 16px;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          transition: 0.2s;

          .area{
            display: flex;
            gap: 6px;

            .areaTitle{
              font-size: 16px;
              font-weight: 400;
              color: var(--palette-grey-500);
            }
          }
        }

        .errorWrapper{
          font-weight: 500;
          font-size: 16px;
          color: var(--palette-error-dark);

          .errorMessage{
            display: flex;
            align-items: center;
            gap: 8px;

            .errorIcon{
              color: var(--palette-error-dark);
            }
          }
        }

        .warningsWrapper{
          min-height: 95px;
          color: var(--palette-warning-dark);
          background-color: var(--palette-warning-light);
          border: 1.5px dashed var(--palette-warning-dark);
          border-radius: 8px;
          padding: 12px 16px;
          display: flex;
          flex-direction: column;
          gap: 4px;

          .warningMessageWrapper{
            font-weight: 500;
            color: var(--palette-warning-dark);
            font-size: 14px;

            .warningMessage{
              display: flex;
              align-items: center;
              gap: 8px;

              .warningIcon{
                color: var(--palette-warning-dark);
              }
            }
          }

          .accordion{
            min-height: 40px;
            border: solid 1px var(--palette-grey-100);
            border-radius: 8px;
            margin-left: 32px;

            .accordionDetails{
              display: flex;
              background-color: var(--palette-grey-background);
              flex-direction: column;
              height: 160px;
              border-radius: 8px;
              overflow-y: auto;

              .accordionDetailsContent{
                padding-left: 12px;
                display: flex;
                align-items: center;
                gap: 8px;
                flex-direction: column;

                .accordionDetailsContentItem{
                  color: var(--palette-grey-500);
                  padding-left: 25px;
                }
              }
            }
          }
        }
      }

      .helpMessageWrapper{
        display: flex;
        flex-direction: row;
        gap: 5px;
        font-weight: 400;
        font-size: 14px;
        color: var(--palette-grey-500);

        .helpMessage{
          display: flex;
          gap: 8px;
          align-items: center;
          text-decoration: none;
          color: var(--palette-info-dark);
          cursor: pointer;

          .helpMessageIcon{
            color: var(--palette-info-dark);
          }
        }
      }
    }

    .mainTitle{
      font-weight: 500;
      font-size: 14px;
      color: var(--palette-grey-900);
    }

    .groupWrapper{
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 4px;

      .groupWrapperTitle{
        font-weight: 400;
        font-size: 14px;
        color: var(--palette-grey-500);
      }
    }

    .footerWrapper{
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 100%;

      .divider{
        color: var(--color-coding-grayscale-100);
      }

      .buttonsWrapper{
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 12px;
      }
    }
  }
}