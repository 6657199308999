.wrapper{
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 400px;
  height: 100%;

  .header{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 12px;
  }

  .wrapperContent{
    display: flex;
    flex-direction: column;
    justify-content: start;
    overflow: auto;
    height: max-content;
    gap: 12px;
    flex-grow: 1;

    .content{
      display: flex;
      flex-direction: column;
      justify-content: start;
      overflow: auto;
      height: max-content;
      flex-grow: 1;
      gap: 12px;

      .headerWrapper{
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 4px;
        padding-left: 13px;
        margin-top: 16px;
        border-bottom-color: var(--palette-grey-100);

        .header{
          color: var(--palette-grey-900);
        }
      }

      .buttonWrapper{
        display: flex;
        gap: 12px;
        justify-content: end;
        padding-bottom: 24px;
      }

    }
  }
}