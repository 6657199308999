.detailsHeader{
  height: auto;
  border-bottom: 1px solid var(--palette-grey-100);

  .title{
    color: var(--palette-grey-600);
    font-size: 24px;
    white-space: nowrap;
  }
}

.detailsWrapper{
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: auto;

  .content{
    display: flex;
    flex-direction: column;
    gap: 16px;
    border: 1px solid var(--palette-grey-100);
    border-radius: 12px;
    padding: 24px;

    .sectionWrapper{
      color: var(--palette-grey-600);
      padding: 16px;
      border-radius: 10px;
      border: 1px solid var(--palette-grey-100);
      display: flex;
    }

    .locationsWrapper{
      flex-wrap: wrap;
      align-items: center;
    }

    .wdWrapper{
      flex-direction: column;
      gap: 8px;

      .wdContent{
        padding-bottom: 16px;
        border-bottom: 1px solid var(--palette-grey-100);
      }
    }

    .contactsWrapper{
      gap: 12px;

      .contactContent{
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 50%;
        flex-wrap: wrap;

        .notificationChip{
          font-weight: 400;
          font-size: 14px;
          color: var(--palette-info-dark);
          display: flex;
          gap: 8px;
          flex-wrap: wrap;
        }
      }
    }

    .reportWrapper{
      padding: 10px;
    }
  }
  .actionItemsWrapper{
    border: 1px solid var(--palette-grey-100);
    padding: 24px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .bigTitle{
    color: var(--palette-grey-600);
    font-weight: 500;
    font-size: 16px;
  }

  .middleTitle{
    color: var(--palette-grey-900);
    font-weight: 400;
    font-size: 14px;
  }

  .smallTitle{
    color: var(--palette-grey-600);
    font-weight: 500;
    font-size: 12px;
  }
}

.locationChipWrapper{
  background: #fff;
  line-height: 28px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .locationChip{
    font-size: 14px;
    color: var(--palette-grey-700);
    background-color: var(--palette-grey-100);
    border-radius: 8px;
    padding: 4px 10px;
    margin-right: 10px;

    .chipIcon{
      vertical-align: middle;
      padding-bottom: 2px;
      width: 16px;
      margin-right: 5px;
    }
  }
}

.contactWrapper{
  padding: 5px 8px;
  background-color: var(--palette-grey-100);
  border-radius: 8px;
}