.mapWrapper{
    flex-grow: 1;
    width: 100%;
}
.wrapperMapMenu{
    display: flex;
    width: 100%;
    height: 50px;
    padding: 10px 15px;
}
.wrapperMapMenu input{
    width: 20%;
}
.wrapperLocationMap{
    width: 100%;
    height: 100%;
    padding: 25px 0;
    display: block;
}
#locationmap{
    width: 100%;
    height: 100%;
    display: block;
    box-sizing: content-box;
}
.basemap{
    width:100%;
    height: 100%;
}

.productpqswitcher{
    border: none;
    box-shadow: 0 0 0 2px rgba(0,0,0,.1);
    transition: 0.2s;
}
.productpqswitcher.active {
    background-color: #b4deff;
}

.productpqswitcher:hover {
    background-color: #F0F2F5 !important;
}

.maplibregl-popup-close-button{
    width: 24px;
    height: 24px;
    font-weight: 100;
    background-color: white;
    color: var(--palette-grey-500);
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid var(--palette-grey-100);
    top: 5px;
    right: 5px;
}

.extra-button{
    background: var(--palette-primary-dark);
    color: white;
    border: none;
    border-radius: 6px;
    padding: 5px;
    margin-left: 5px;
    width: fit-content;
    cursor: pointer;
    transition: .25s;
}

.extra-button:hover{
    background: var(--palette-primary-middle);
    transition: .25s;
}

.maplibregl-popup-close-button:hover {
    background-color: var(--palette-grey-100);
}

