.checkedContentWrapper{
  display: flex;
  align-items: center;
  padding-left: 32px;
  gap: 12px;
  align-self: stretch;

  .checkedContent{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;

    .defaultMessage{
      color: var(--palette-grey-500);
    }

    .button{
      height: 32px;
      white-space: nowrap;
    }
  }
}