.itemsDeleteVisible{
    background-Color: #eee;
    border-Top: 1px solid #ccc;
    border-Bottom: 1px solid #ccc;
    padding: 5px 20px 5px 35px;
    gap: 8px;
    transition: 0.35s;
    opacity: 1;
    height: 52px;
}

.itemsDeleteInvisible{
    opacity: 0;
    height: 0;
    border: 0;
    padding: 0 20px 0 35px;
    transition: 0.35s;
}


