.wrapper{
  gap: 8px;

  .contentWrapper{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
    padding-left: 32px;

    .content{
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      align-self: stretch;

      .messageWrapper{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;

        .defaultMessage{
          color: var(--palette-grey-500);
        }

        .button{
          height: 32px;
          white-space: nowrap;
        }
      }
    }

    .formWrapper{
      display: flex;
      flex-direction: row;
      align-items: center;
      align-self: stretch;
      gap: 8px;
      padding: 4px 0 0;

      .select{
        width:108px;
      }
    }
  }
}