.wrapper{
  width: 470px;
  display: flex;
  flex-direction:column;
  gap:24px;

  .header{
    justify-content: space-between;
  }

  .messageWrapper{
    display: flex;
    flex-direction: column;
    gap: 4px;

    .messageTitle{
      color: var(--palette-grey-500);
    }

    .message{
      min-width: 100%;
      max-width: 100%;
      resize: both;
      overflow: auto;
      border-radius: 8px;
      padding: 12px;
      border: 1px solid var(--palette-grey-100);
      user-select: text !important;
    }
  }

  .tokensWrapper{
    flex-wrap: wrap;

    .tokenItem{
      height:24px;
      font-size:14px;
    }
  }

  .footerWrapper{
    padding-top: 12px;
    border-top: 1px solid var(--palette-grey-100);

    .button{
      width: 100%;
    }
  }
}