.wrapper{
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-coding-grayscale-100, #F0F2F5);
  border-radius: 8px;
  padding: 16px;
  gap: 8px;
  overflow: auto;

  .buttonWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;

    .button{
      font-size: 12px;
      font-weight: 500;
      color: #333333;
    }
  }

  .mainWrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;

    .chipsWrapper{
      display: flex;
      gap: 8px;
    }

    .listWrapper{
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}