.condicon {
    background:url('../images/conds.svg');
    background-size: 27.9em;
    width: 2em;
    height: 2em;
    display: inline-block;
    font-size:12px;
    margin:5px 2px;
}
.cond-0002 {
    background-position: -3.85em  -14.25em;
}
.cond-0003 {
    background-position: -6.5em   -14.25em;
}
.cond-0004 {
    background-position: -9.1em   -14.25em;
}
.cond-0005 {
    background-position: -11.75em -14.25em;
}
.cond-0006 {
    background-position: -14.2em  -14.25em;
}
.cond-0011 {
    background-position: -16.9em    -14.25em;
}
.cond-0012 {
    background-position: -19.55em -14.25em;
}
.cond-0013 {
    background-position: -22.2em  -14.25em;
}

.cond-0014 {
    background-position: -3.85em -16.85em;
}
.cond-0016 {
    background-position: -6.5em -16.85em;
}
.cond-0017 {
    background-position: -9.1em -16.85em;
}
.cond-0018 {
    background-position: -11.75em -16.85em;
}
.cond-0019 {
    background-position: -14.2em -16.85em;
}
.cond-0020 {
    background-position: -16.9em -16.85em;
}
.cond-0021 {
    background-position: -19.55em -16.85em;
}
.cond-0022 {
    background-position: -22.2em -16.85em;
}




.cond-0023 {
    background-position: -3.85em -19.45em;
}
.cond-0024 {
    background-position: -6.5em -19.45em;
}
.cond-0025 {
    background-position: -9.1em -19.45em;
}
.cond-0026 {
    background-position: -11.75em -19.45em;
}
.cond-0027 {
    background-position: -14.2em -19.45em;
}
.cond-0028 {
    background-position: -16.9em -19.45em;
}
.cond-0029 {
    background-position: -19.55em -19.45em;
}
.cond-0030 {
    background-position: -22.2em -19.45em;
}


.cond-2012 {
    background-position: -3.85em -22.15em;
}
.cond-2013 {
    background-position: -6.5em -22.15em;
}
.cond-2014 {
    background-position: -9.1em -22.15em;
}
.cond-2016 {
    background-position: -11.75em -22.15em;
}
.cond-2017 {
    background-position: -14.2em -22.15em;
}
.cond-2018 {
    background-position: -16.9em -22.15em;
}
.cond-2019 {
    background-position: -19.55em -22.15em;
}
.cond-2020 {
    background-position: -22.2em -22.15em;
}


.cond-2021 {
    background-position: -3.85em -24.7em;
}
.cond-2022 {
    background-position: -6.5em -24.7em;
}
.cond-2023 {
    background-position: -9.1em -24.7em;
}
.cond-2024 {
    background-position: -11.75em -24.7em;
}
.cond-2025 {
    background-position: -14.2em -24.7em;
}
.cond-2026 {
    background-position: -16.9em -24.7em;
}
.cond-2027 {
    background-position: -19.55em -24.7em;
}
.cond-2028 {
    background-position: -22.2em -24.7em;
}

.cond-2029 {
    background-position: -3.85em -27.3em;
}
.cond-2030 {
    background-position: -6.5em -27.3em;
}
.cond-9000-day {
    background-position: -9.1em -27.3em;
}
.cond-9001-day {
    background-position: -11.75em -27.3em;
}
.cond-9002-day {
    background-position: -14.2em -27.45em;
}
.cond-9003-day {
    background-position: -16.9em -27.3em;
}
.cond-9004 {
    background-position: -19.55em -27.3em;
}
.cond-9005 {
    background-position: -22.2em -27.3em;
}


.cond-9100 {
    background-position: -3.85em -29.9em;
}
/*
.cond-9100-day {
    background-position: -3.85em -29.9em;
}
.cond-9100-night {
    background-position: -6.5em -29.9em;
}
*/
.cond-9101 {
    background-position: -9.1em -29.9em;
}
.cond-9102 {
    background-position: -11.75em -29.9em;
}
.cond-9103 {
    background-position: -14.2em -29.9em;
}
.cond-9104 {
    background-position: -16.9em -29.9em;
}
.cond-9105 {
    background-position: -19.55em -29.9em;
}
.cond-9106 {
    background-position: -22.2em -29.9em;
}


.cond-9107 {
    background-position: -3.85em -32.5em;
}
.cond-9200 {
    background-position: -6.5em -32.5em;
}
.cond-9201 {
    background-position: -9.1em -32.5em;
}
.cond-9000-night {
    background-position: -11.75em -32.5em;
}
.cond-9001-night {
    background-position: -14.2em -32.5em;
}
.cond-9002-night {
    background-position: -16.9em -32.5em;
}
.cond-9003-night {
    background-position: -19.55em -32.5em;
}