.wrapper{
  background-color: var(--palette-grey-100);
  display: flex;
  flex-direction: column;
  gap: 24px;

  .header{
    background-color: white;
    padding: 16px 24px;
  }

  .contentWrapper{
    width: 1216px;
    display: flex;
    align-self: center;
    box-shadow: -4px 0px 14px 0px rgba(103, 122, 142, 0.12);
    border-radius: 16px;
    background-color: white;
    margin-bottom: 60px;
  }
}