.impmarker {
    width: 220px;
    max-height: 500px;
}

.metar-marker:hover {
    z-index: 991;
}

.basicsensorwrapper.impmarker-wrapper {
    margin-left: -39px;
    margin-top: -47px !important;
    height: 46px;
    transition: width 0.3s ease-out 0s;
}

.basicsensorwrapper.impmarker-wrapper.opened {
    min-width: 220px;
    height: auto;
}

.basicsensorwrapper.impmarker-wrapper .impmarker-body {
    display: none;
}

.basicsensorwrapper.impmarker-wrapper.opened .impmarker-body {
    display: block;
    padding: 5px 10px;
    box-sizing: border-box;
    width: 100%;
}

.metarcondheader {
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    color: var(--palette-grey-800);
    width: 100%;
    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
}

body .basicsensorwrapper.impmarker-wrapper {
    margin-left: 60px;
    margin-top: -46px !important;
    height: 48px;
    transition: width 0.3s ease-out 0s;
}

.basicsensorwrapper .impmarker.basicsensor .impmarker-header {
    padding: 4px 8px;
    min-height: 48px;
    height: fit-content;
    width: 100%;
    text-indent: 0;
}

.opened .impmarker.basicsensor {
    overflow: visible;
    white-space: normal;
    height: auto;
}

body .impmarker.basicsensor {
    width: 280px;
    border-radius: 8px;
    box-shadow: 0 4px 12px 0 #677A8E3D;
}

body .impmarker.basicsensor .impmarker-body * {
    color: var(--palette-grey-800);
}

.basicsensorwrapper.opened {
    z-index: 1;
}

.basicsensor .impmarker-body {
    width: 220px;
}

.impmarker .metar-temperature {
    line-height: 22px;
}

.impmarker .withwind .metar-temperature {
    margin: 0;
}

.impmarker .condicon {
    margin: 6px 0 6px 4px;
    vertical-align: top;
}

.metar-short-info {
    text-transform: capitalize !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
}

.impmarker .metar-short-info {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    vertical-align: top;
    line-height: 17px;
    font-size: 12px;
    min-width: 140px;
}

.currentConditionHeaderWrapper{
    display: flex;
    flex-direction: column;
}

.currentConditionHeader{
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: var(--palette-grey-900);
}

.currentConditionSubHeader{
    font-size: 12px;
    font-weight: 400;
    color: var(--palette-grey-600);
}

.currentConditionString{
    display: flex;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    border-bottom: 1px solid var(--palette-grey-100);
    max-width: 334px;
}

.currentConditionString:last-child{
    border:none;
}

.currentConditionStringBold{
    font-weight: 600;
    white-space: initial;
}

.currentConditionTitle{
    width: 170px;
}

.currentConditionTitleAdvanced{
    min-width: 120px;
}

.legendsString{
    line-height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.legendsSwitcher{
    background: url('../../../../shared/assets/svg/downArrow.svg') no-repeat center center;
    min-width: 24px;
    width: 24px;
    height: 24px;
    transition: 0.25s;
    border-radius: 8px;
}

.legendsSwitcher.visible {
    background: url('../../../../shared/assets/svg/upArrow.svg') no-repeat center center;
}

.legendsSwitcher:hover{
    transition: 0.25s;
    cursor: pointer;
    background-color: var(--palette-grey-100);
}

.opened .impmarker .metar-short-info{
    white-space: normal;
    overflow: hidden;
}

.impmarker .metar-short-additional {
    height: 16px;
    display: block;
    line-height: 16px;
    font-size: 10px;
}

.isobsolete {
    /*opacity: 0.75;*/
}

.metarswitcher {
    background: url('../../../../shared/assets/svg/rightArrow.svg') no-repeat center center;
    min-width: 24px;
    width: 24px;
    height: 24px;
    border: 1px solid var(--palette-grey-100);
    border-radius: 8px;
    transition: 0.25s;
}

.opened .metarswitcher {
    background: url('../../../../shared/assets/svg/close.svg') no-repeat center center;
}

.metarswitcher:hover{
    transition: 0.25s;
    cursor: pointer;
    background-color: var(--palette-grey-100);
}

/*.basicsensorwrapper::before {*/
/*    width: 0;*/
/*    height: 0;*/
/*    border-style: solid;*/
/*    border-width: 10px 10px 0 10px;*/
/*    border-color: rgba(0, 0, 0, 0.65) transparent transparent transparent;*/
/*    content: '';*/
/*    position: absolute;*/
/*    bottom: -1px;*/
/*    left: 40px;*/
/*}*/

/*.basicsensorwrapper.opened::before {*/
/*    display: none;*/
/*}*/

.nwspopup pre {
    white-space: pre-wrap;
    word-wrap: break-word;
}

.metar-marker:hover {
    z-index: 991;
}

.basicsensorwrapper.impmarker-wrapper {
    margin-left: -39px;
    margin-top: -47px !important;
}

.opened .metarcondheader{
    height: fit-content;
}

.basicsensor .impmarker-header {
    width: 100%;
    text-indent: 0;
}

.opened .impmarker.basicsensor {
    overflow: visible;
    white-space: normal;
    height: auto;
}

.basicsensorwrapper.opened {
    z-index: 99999999 !important;
}

.basicsensorwrapper.opened .impmarker-header,
.basicsensorwrapper.opened .impmarker-body {
    background: var(--palette-grey-background);
}

.basicsensorwrapper.opened .impmarker-header{
    border-radius: 8px 8px 0 0;
    border-bottom: none;
}

.basicsensorwrapper.opened .impmarker-body {
    border-radius: 0 0 8px 8px;
    border-top: none;
}

.impmarker .metar-temperature {
    display: inline-block;
    width: 34px;
    font-size: 20px;
    line-height: 22px;
    height: 22px;
    vertical-align: top;
    margin: 6px 0;
}

.impmarker .withwind .metar-temperature {
    margin: 0;
}

.impmarker .condicon {
    margin: 6px 0 6px 4px;
    vertical-align: top;
}

.impmarker .withwind .condicon {
    margin: 0;
}

.impmarker .metar-short-additional {
    height: 16px;
    display: block;
    line-height: 16px;
    font-size: 10px;
}

.metar-switch {
    display: inline-block;
    vertical-align: top;
    height: 36px;
    width: 32px;
    background: url('../../../../shared/assets/svg/close_btn.png') no-repeat 10px 12px;
    border-right: 5px solid #d11f30;
}

.opened .metar-switch {
    background-position: 10px -56px;
}

.temp-and-metar-wind-short{
    display: flex;
    gap: 4px;
    width: fit-content;
    align-items: center;
}

.temp-and-icon{
    align-items: center;
    display: flex;
    gap: 2px;
}

.metar-wind-short {
    line-height: 9px;
    font-size: 9px;
}

.metaranchor {
    background: url('../../../../shared/assets/svg/bckanchor.png');
    position: absolute;
    width: 19px;
    height: 10px;
    top: 37px;
    z-index: -2;
    left: 28px;
}

.opened .metaranchor {
    display: none;
}

.metars-legends {
    border-radius: 10px;
    padding: 0 6px;
    position: absolute;
    left: -1px;
    width: 352px;
    height: 0;
    background: var(--palette-grey-background);
    transition: 0.25s;
    overflow: hidden;
    border: none;
    opacity: 0;
}

.metars-legends.visible {
    transition: 0.25s;
    height: fit-content;
    padding: 6px 6px 2px 6px;
    display: flex;
    justify-content: space-between;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 4px 12px -4px #677A8E3D;
    opacity: 1;
}

.metars-legends span {
    vertical-align: top;
    line-height: 35px;
    margin: 0 10px 0 3px;
}

.metars-help-button {
    color: white;
    width: 16px;
    font-size: 8px;
    height: 16px;
    border-radius: 8px;
    background: #d11f30;
    line-height: 16px;
    text-align: center;
    float: right;
    margin-right: 9px;
    cursor: help;
}

.metar-help-station {
    border: 2px solid var(--palette-grey-200);
    width: 24px;
    height: 24px;
    border-radius: 13px;
    display: inline-block;
    margin: 4px;
}

.vfr {
    background: #fff;
}

.mvfr {
    background: #1b00fd;
}

.ifr {
    background: #fc0021;
}

.lifr {
    background: #9B11B2
}

.fullst {
    background: #fff;
}

.emptyst {
    background: var(--palette-grey-200);
}

.small-few {
    width: 11px;
    height: 11px;
    position: relative;
    top: 0;
    right: 0;
    left: 9px;
    background: var(--palette-grey-200);
    border-radius: 0 13px 0 0;
}

.small-half {
    width: 11px;
    height: 20px;
    background: #fff;
    border-radius: 13px 0 0 13px;
}

.small-broken {
    width: 10px;
    height: 11px;
    background: #fff;
    border-radius: 13px 0 0 0;
}

.help-stations {
    display: inline-block;
    vertical-align: top;
    text-align: left;
}

.wind-help {
    width: 22px;
    height: 22px;
    background: url('../../../../../public/img/wind-help.png') transparent;
    display: inline-block;
    margin: 8px 0 0;
}

.wind-help-2 {
    background-position: 0 -46px;
}

.wind-help-3 {
    background-position: 0 -84px;
}

.wind-help-4 {
    background-position: 0 -128px;
}

.metar-barb-name {
    position: absolute;
    width: 48px;
    top: -1px;
    left: 12px;
    font: 16px/16px "RobotoMedium", "RobotoLight", "Arial", "sans-serif";
    color: #fff;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    padding-top: 4px;
    background-origin: border-box;
    text-align: center;
}

.metar-barb-temperature, .metar-barb-dew {
    font: 16px/16px "RobotoMedium", "RobotoLight", "Arial", "sans-serif";
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
    position: absolute;
    left: -35px;
}

.metar-barb-temperature {
    top: -23px;
    color: #ff0000;
}

.metar-barb-dew {
    top: 0;
    color: #1c9600;
}

.metar-barb-circle {
    height: 20px;
    width: 20px;
    position: absolute;
    top: -10px;
    left: -10px;
    background-position: 2px 2px;
    background-repeat: no-repeat;
}

.isobsolete {
    /*opacity: 0.75;*/
}

.marker-header {
    width: 260px;
    margin-bottom: 12px;
    font-weight: 600;
    white-space: initial;
}

.productListCurrentConditionWrapper{
    background-color: white;
    padding: 4px 16px 10px 24px !important;
    border-radius: 8px;
}

.productListCurrentConditionLabel{
    color: #333333;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
}