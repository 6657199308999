.wrapper{
  padding: 0;
  border: 1px solid var(--palette-grey-100);

  .summaryWrapper{
    width: auto;
    display: flex;
    gap: 8px;
    align-items: center;
    min-height: 40px;
    height: 40px;

    .summaryContent{
      display: flex;
      align-items: center;
      flex-grow: 1;
      gap: 8px;
    }
  }

  .detailsWrapper{
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: var(--palette-grey-background);
  }
}