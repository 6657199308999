.wrapper{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 504px;
  gap: 24px;
  padding: 12px 16px;

  .headerWrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
  }

  .infoWrapper{
    height: 48px;
    padding: 8px 16px;
    border: 1px solid var(--palette-grey-100);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .info{
      font-size: 16px;
      font-weight: 500;
      color: var(--palette-grey-900);
    }
  }

  .footerWrapper{
    display: flex;
    flex-direction: column;
    gap: 12px;

    .buttonsWrapper{
      display: flex;
      gap: 12px;

      .button{
        font-weight: 400;
        height: 40px;
        width: 100%;
      }
    }
  }
}