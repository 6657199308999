.background {
    width: 100vw;
    height: 100vh;
}

.background .container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.background .baronLogo {
    height: 34px;
    width: auto;
}

.section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-column: 1;
    grid-row: 1;
}

.section-enter {
    opacity: 0;
}

.section-enter-active {
    opacity: 1;
    transition: opacity 1000ms;
}

.section-exit {
    opacity: 1;
}

.section-exit-active {
    opacity: 0;
    transition: opacity 1000ms;
}

.sectionHeader {
    display: flex;
    margin-bottom: 32px;
    gap: 8px;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}

.form {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    width: 688px;
    padding: 40px;
    border: 1px solid #F0F2F5;
    border-radius: 12px;
    background-color: white;
    gap: 16px;
}