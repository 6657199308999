.switch-mode-tool{
    margin: 10px;
    display: block;
    width: 28px;
    height: 28px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    background: url('../../../../shared/assets/svg/switch_button.svg') center center no-repeat #fff;
    background-size: 20px 20px;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, .1);
    transition: 0.2s;

    img {
        width: 16px;
        height: 16px;
    }
}

.switch-mode-tool.active{
    background-color: #b4deff;
}

.switch-mode-tool:hover {
    background-color: #F0F2F5 !important;
}