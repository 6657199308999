.wrapper{
  flex: 1 1 auto;
  height: 100% !important;
  background-color: white;
  border-radius: 16px;

  .mainContentWrapper{
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    gap: 12px;
    align-items: start;
    overflow: hidden;

    .searchAndMapSide{
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      gap: 16px;
    }

    .menuSideWrapper{
      min-width: 384px;
      width: 384px;
      height: 100%;
      overflow: hidden;
      justify-content: flex-start;

      .menuSide{
        display: flex;
        flex-direction: column;
        gap: 12px;
        height: 100%;
        padding-top: 56px;

        .menuSideContent{
          display: flex;
          flex-direction: column;
          gap: 8px;

          .selectedLocationsAccordion{
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 16px 12px;
            border: solid 1px var(--palette-grey-100);
            border-radius: 8px;
            background-color: var(--palette-grey-background);

            .selectedLocationsAccordionSummary{
              width: 100%;
              height: 32px;
              display: flex;
              align-items: center;
              border-radius: 8px;
              padding: 0;
            }

            .selectedLocationsAccordionDetails{
              width: 100%;
              display: flex;
              flex-direction: column;
              background: var(--palette-grey-background);
              padding: 2px;
              gap: 8px;
              margin-top: 8px;
              max-height: 280px;
              overflow-y: auto;
            }
          }
        }

        .newLocationMenuWrapper{
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          width: 100%;
          height: 148px;
          padding: 16px 12px;
          border: solid 1px var(--palette-grey-100);
          border-radius: 8px;
          background-color: var(--palette-grey-background);

          .newLocationMenu{
            display: flex;
            flex-wrap: wrap;
            gap: 8px;

            .newLocationButton{
              width: 174px;
              font-weight: 400;
              font-size: 14px;
              box-shadow: 0 1px 3px 0 #677A8E3D;
            }
            .importButton{
              box-shadow: 0 1px 3px 0 #677A8E3D;
            }
          }
        }

        .existingLocationsButtonWrapper{
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: 100%;
          height: 100%;
          padding: 16px 12px;
          border: solid 1px var(--palette-grey-100);
          border-radius: 8px;
          background-color: var(--palette-grey-background);

          .existingLocationsButton{
            width: 100%;
            height: 56px;
            display: flex;
            justify-content: space-between;
            padding: 16px;
            font-weight: 400;
            font-size: 14px;
            box-shadow: 0 1px 3px 0 #677A8E3D;
          }

          .existingLocationsIconButton{
            width: 32px;
            height: 32px;
            border: 1px solid var(--palette-grey-100);
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .addLocationMenuWrapper{
          display: flex;
          gap: 16px;
          flex-direction: column;
          align-items: start;

          .addLocationMenu{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 12px;
            padding: 2px;
          }
        }

        .existingLocationsMenuWrapper{
          height: fit-content;
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          gap: 16px;

          .existingLocationsMenuHeader{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 12px;
            padding: 2px;
          }

          .allLocationsCheckBox{
            padding: 8px 10px 8px 12px;
            border-bottom: 1px solid var(--palette-grey-100);
            cursor: pointer;
            margin-bottom: -8px;
          }

          .listOfExistingLocationsWrapper{
            display: flex;
            flex-direction: column;
            align-items: stretch;
            align-content: flex-start;
            height: fit-content;

            .existingLocationsItem{
              margin-bottom: 16px;
            }
          }
        }
      }
    }

    .blockTitle{
      font-weight: 400;
      font-size: 14px;
      color: var(--palette-grey-600);
    }

    .squareButton{
      height: 32px;
      width: 32px;
    }

    .listItemTitle{
      color: var(--palette-grey-900);
    }
  }

  .lastCreatedLocationsWrapper{
    display: flex;
    flex-direction: column;
    gap: 4px;

    .lastCreatedLocationsTitle{
      color: var(--palette-grey-500);
    }

    .lastCreatedLocationsList{
      max-height: 95px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }
}