.contentWrapper{
  align-items: start !important;

  .iconWrapper{
    position:relative;

    .iconContent{
      position:absolute;
      width:30px;
      height:30px;
      border-radius:6px;
      background-color: var(--palette-common-lightGreen);
      display:flex;
      justify-content:center;
      align-items:center;
      top: 27%;

      .icon{
        width: 18px;
        height: 18px;
        fill: white;
      }
    }
  }
}