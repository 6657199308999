.wrapper{
  width: 472px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  .titleWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    .buttonWrapper{
      height: 32px;
      padding: 8px;

      .button{
        width: 16px;
        height: 16px;
      }
    }
  }

  .contentWrapper{
    display: flex;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    flex-direction: column;

    .subtitle{
      font-size: 14px;
      font-weight: 400;
      margin: 0;
    }

    .textField{
      gap: 8px;
      width: 100%;
    }
  }

  .buttonsWrapper{
    display: flex;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    padding-top: 12px;
    border-top: 1px solid var(--palette-grey-100);
  }
}