.infoPanel{
  display:flex;
  gap: 8px;
  flex-direction: column;
  border-radius: 16px;
  width: 100%;
  background-color: var(--palette-grey-100);

  .infoPanelTitle{
    font-weight: 500;
    font-size: 16px;
    display: flex;
    gap: 8px;
    color: var(--palette-grey-900);
  }

  .infoPanelText{
    font-weight: 400;
    font-size: 14px;
    color: var(--palette-grey-500);
    line-height: 20px;
  }
}

.infoPanelVisible{
  height: auto;
  opacity: 1;
  padding: 24px;
  transition: 0.2s;
}

.infoPanelHide{
  height: 0;
  opacity: 0;
  padding: 0 24px;
  transition: 0.2s;
}

