.header {
    height: 60px;
    padding: 16px 24px 12px 24px;
    flex-grow: 1;
}

.header .baronLogo {
    width: 129px;
    height: 34px;
}

.header .baronLogo img {
    height: 34px;
}