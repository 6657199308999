.clearbutton-dropdown {
    display: flex;
    flex-direction: column;
    border: 1px solid #CCCCCC;
    border-radius: 8px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08);
    margin-top: 5px;
    padding: 10px;
    position: absolute;
    z-index: 9999;
    background: #FFF;
    width: 250px;
    right: 5px;
}