.wrapper{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 504px;
  gap: 24px;
  padding: 12px 16px;

  .header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
  }

  .imagesWrapper{
    position: relative;
    height: 80px;
    overflow: hidden;
  }

  .contentWrapper{
    display: flex;
    flex-direction: column;
    gap: 12px;

    .content{
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title{
        font-size: 16px;
        font-weight: 500;
        color: var(--palette-grey-900);
      }
    }

    .summaryWrapper{
      font-size: 14px;
      font-weight: 500;
      color: var(--palette-grey-900);
      display: flex;
      gap: 4px;
      align-items: center;
    }

    .listWrapper{
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      max-height: 90px;
      overflow: auto;
    }
  }

  .footerWrapper{
    display: flex;
    flex-direction: column;
    gap: 12px;

    .buttonsWrapper{
      display: flex;
      gap: 12px;

      .button{
        font-weight: 400;
        height: 40px;
        width: 100%;
      }
    }
  }
}