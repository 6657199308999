.titlesWrapper{
  width: auto;
  display: flex;
  align-items: center;

  .titleWrapper{
    width: 140px;
    flex-grow: 1
  }

  .title{
    color: var(--palette-other-tooltip);
  }

  .emailTitleWrapper{
    width: 30px;
    flex-grow: 1;
  }

  .phoneTitleWrapper{
    width: 254px;
  }
}

.recipientsListWrapper{
  max-height: none;

  .groupNameWrapper{
    cursor: pointer;
    width: 200px;
    flex-grow: 1;
  }

  .groupEmailWrapper{
    width: 107px;
    flex-grow: 1;
  }

  .groupPhoneWrapper{
    width: 197px;
  }

  .groupTitle{
    color: var(--palette-grey-500);
  }
}