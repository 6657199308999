.wrapper{
  width: 470px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    line-height: 32px;

    .iconWrapper{
      height: 32px;
      padding: 8px;

      .icon{
        width: 16px;
        height: 16px;
      }
    }

    .title{
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .contentWrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;

    .nameHeader{
      display: flex;
      align-items: flex-start;
      gap: 4px;
      align-self: stretch;
      flex-direction: column;

      .name{
        font-size: 14px;
        font-weight: 400;
        margin: 0;
      }
    }

    .selectWrapper{
      display: flex;
      gap: 8px;
      align-items: center;

      .selectContent{
        width: 24px;
        height: 24px;
        justify-content: center;
        border: 1px solid var(--palette-grey-100);
        border-radius: 4px;
        background-color: var(--palette-grey-background);

        .svgBackground{
          fill: var(--palette-primary-dark);
        }
      }

      .titleOfSelect{
        font-size: 14px;
        font-weight: 400;
      }

      .selectedTitle{
        color: var(--palette-primary-dark);
      }

      .unselectedTitle{
        color: var(--palette-grey-300);
      }
    }
  }

  .buttonsWrapper{
    display: flex;
    gap: 12px;
    width: 100%;

    .button{
      font-weight: 400;
      width: 50%;
    }
  }
}

.selectedIcon{
  fill: var(--palette-primary-dark);
}