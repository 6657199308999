.locationWrapper{
    width: 100%;
    height: 100%;
    border: 2px solid gainsboro;
    border-radius: 7px;
    padding: 20px 15px;
    margin: 10px;
}
.locationWrapper .locationsButtons{
    display: flex;
    flex-direction: row;
}
input{
    width: 100%;
}
#mapsearch{
    width: 100%;
    height: 100%;
    padding: 15px;
}
.pinlocation{
    width: 40px;
    height: 40px;
    margin: 10px 0 0 15px;
}

.locationGroup {
    padding: 8px 12px 8px 32px !important;
    width: 100%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.filteredLocationGroup {
    padding-left: 36px;
    width: 100%;
    border-radius: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
}