.location *{
}
.citieslist {
    width: 320px;
    position: absolute;
    z-index: 1;
    background: #ffffff;
}
.citieslist .citieslistitem div{
    display: inline-block;
    vertical-align: top;
}
.citieslist .citieslistitem{
    height: 38px;
    border-bottom: 1px solid #DFE1E5;
    margin: 0 25px;
    padding: 0 5px;
    line-height: 38px;
    white-space: nowrap;
}
.citieslist .citieslistitem:last-child{
    border-bottom: none;
}
.citieslist .citieslistitem .citieslistlabel{
    width: 190px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
}

.locationsearchwrapper input{
    width: 470px;
    margin: 15px 5px;
    height: 40px;
    border: 1px solid var(--main-border-color);
    border-radius: 8px;
    text-indent: 25px;
    background-size: 16px 16px;
    box-shadow: 0px 1px 3px rgba(103, 122, 142, 0.24);
}
.locationsearchicon{
    position: absolute;
    width: 15px;
    margin: 14px 0 0 36px;
}
.locationsearchicon .cls-1{
    fill: #7e7e7e;
}
.location .citieslist{
    max-height: 200px;
    overflow: auto;
}
.locationheader div{
    display: inline-block;
}
.recentlist{
    width: 320px;
}
.recentlist label{
    padding: 0 15px;
    color: #7F7F7F;
    text-transform: uppercase;
    display: block;
    height: 40px;
    line-height: 40px;
    letter-spacing: 0.5px;
    font-size: 15px;
}