.condicon {
    background: url('WeatherConditions.svg');
    background-size: 27.9em;
    width: 2em;
    height: 2em;
    display: inline-block;
    font-size: 12px;
    margin: 5px 2px;
}

.weather-condition-0002 {
    background-position: -3.85em -14.25em;
}

.weather-condition-0003 {
    background-position: -6.5em -14.25em;
}

.weather-condition-0004 {
    background-position: -9.1em -14.25em;
}

.weather-condition-0005 {
    background-position: -11.75em -14.25em;
}

.weather-condition-0006 {
    background-position: -14.2em -14.25em;
}

.weather-condition-0011 {
    background-position: -16.9em -14.25em;
}

.weather-condition-0012 {
    background-position: -19.55em -14.25em;
}

.weather-condition-0013 {
    background-position: -22.2em -14.25em;
}

.weather-condition-0014 {
    background-position: -3.85em -16.85em;
}

.weather-condition-0016 {
    background-position: -6.5em -16.85em;
}

.weather-condition-0017 {
    background-position: -9.1em -16.85em;
}

.weather-condition-0018 {
    background-position: -11.75em -16.85em;
}

.weather-condition-0019 {
    background-position: -14.2em -16.85em;
}

.weather-condition-0020 {
    background-position: -16.9em -16.85em;
}

.weather-condition-0021 {
    background-position: -19.55em -16.85em;
}

.weather-condition-0022 {
    background-position: -22.2em -16.85em;
}


.weather-condition-0023 {
    background-position: -3.85em -19.45em;
}

.weather-condition-0024 {
    background-position: -6.5em -19.45em;
}

.weather-condition-0025 {
    background-position: -9.1em -19.45em;
}

.weather-condition-0026 {
    background-position: -11.75em -19.45em;
}

.weather-condition-0027 {
    background-position: -14.2em -19.45em;
}

.weather-condition-0028 {
    background-position: -16.9em -19.45em;
}

.weather-condition-0029 {
    background-position: -19.55em -19.45em;
}

.weather-condition-0030 {
    background-position: -22.2em -19.45em;
}


.weather-condition-2012 {
    background-position: -3.85em -22.15em;
}

.weather-condition-2013 {
    background-position: -6.5em -22.15em;
}

.weather-condition-2014 {
    background-position: -9.1em -22.15em;
}

.weather-condition-2016 {
    background-position: -11.75em -22.15em;
}

.weather-condition-2017 {
    background-position: -14.2em -22.15em;
}

.weather-condition-2018 {
    background-position: -16.9em -22.15em;
}

.weather-condition-2019 {
    background-position: -19.55em -22.15em;
}

.weather-condition-2020 {
    background-position: -22.2em -22.15em;
}


.weather-condition-2021 {
    background-position: -3.85em -24.7em;
}

.weather-condition-2022 {
    background-position: -6.5em -24.7em;
}

.weather-condition-2023 {
    background-position: -9.1em -24.7em;
}

.weather-condition-2024 {
    background-position: -11.75em -24.7em;
}

.weather-condition-2025 {
    background-position: -14.2em -24.7em;
}

.weather-condition-2026 {
    background-position: -16.9em -24.7em;
}

.weather-condition-2027 {
    background-position: -19.55em -24.7em;
}

.weather-condition-2028 {
    background-position: -22.2em -24.7em;
}

.weather-condition-2029 {
    background-position: -3.85em -27.3em;
}

.weather-condition-2030 {
    background-position: -6.5em -27.3em;
}

.weather-condition-9000-day {
    background-position: -9.1em -27.3em;
}

.weather-condition-9001-day {
    background-position: -11.75em -27.3em;
}

.weather-condition-9002-day {
    background-position: -14.2em -27.45em;
}

.weather-condition-9003-day {
    background-position: -16.9em -27.3em;
}

.weather-condition-9004 {
    background-position: -19.55em -27.3em;
}

.weather-condition-9005 {
    background-position: -22.2em -27.3em;
}


.weather-condition-9100 {
    background-position: -3.85em -29.9em;
}

/*
.weather-condition-9100-day {
    background-position: -3.85em -29.9em;
}
.weather-condition-9100-night {
    background-position: -6.5em -29.9em;
}
*/
.weather-condition-9101 {
    background-position: -9.1em -29.9em;
}

.weather-condition-9102 {
    background-position: -11.75em -29.9em;
}

.weather-condition-9103 {
    background-position: -14.2em -29.9em;
}

.weather-condition-9104 {
    background-position: -16.9em -29.9em;
}

.weather-condition-9105 {
    background-position: -19.55em -29.9em;
}

.weather-condition-9106 {
    background-position: -22.2em -29.9em;
}


.weather-condition-9107 {
    background-position: -3.85em -32.5em;
}

.weather-condition-9200 {
    background-position: -6.5em -32.5em;
}

.weather-condition-9201 {
    background-position: -9.1em -32.5em;
}

.weather-condition-9000-night {
    background-position: -11.75em -32.5em;
}

.weather-condition-9001-night {
    background-position: -14.2em -32.5em;
}

.weather-condition-9002-night {
    background-position: -16.9em -32.5em;
}

.weather-condition-9003-night {
    background-position: -19.55em -32.5em;
}