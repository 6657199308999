.distance-tool {
    margin: 10px;
    display: block;
    width: 28px;
    height: 28px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    background: url('../../../../shared/assets/svg/ruler.svg') center center no-repeat #fff;
    background-size: 17px 17px;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, .1);
}

.distance-tool.active {
    background-color: #b4deff;
}

.point{
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: white;
    border: 5px solid var(--palette-primary-dark);
    opacity: 1;
    position: absolute;
    top: 25px;
    left: 4px;
}

.customMarker{
    svg:first-child{
        opacity: 0;
    }
}