.wrapper{
  border: 1px solid var(--palette-grey-100);

  .accordionSummary{
    width: auto;
    display: flex;
    gap: 8px;
    align-items: center;
    min-height: 56px;
    height: 40px;

    .infoWrapper{
      display: flex;
      align-items: center;
      flex-grow: 1;
      gap : 8px;

      .info{
        font-size: 16px;
        font-weight: 500;
        color: #333333;
      }
    }
  }

  .accordionDetails{
    border-radius: 8px;
    background-color: var(--palette-grey-background);
    box-shadow: 0 1px 3px 0 #677A8E3D;

    .buttonWrapper{
      display: flex;
      gap: 16px;
    }
  }
}