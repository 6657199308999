.history-timepin {
    position: relative;
    height: 16px;
    width: 16px;
    background-color: #000;
    border-radius: 8px;
    top: -8px;
    left: -8px;
}
.info-box-circle {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    float: left;
    margin-right: 10px;
    box-shadow: 1px 1px 2px 0px #aaa;
}
.th-fan {
    width: 25px;
    height: 34px;
}
.th-base {
    width: 39px;
    height: 39px;
}
.th-timepin {
    background: rgba(35,35,35,0.65);
    width: 175px;
    height: 32px;
    color: #fff;
    cursor: pointer;
    margin-top: -17px;
    pointer-events: all;
    position: relative;
}
.th-timepin:before {
    left: 100%;
    top: 9px;
    margin-left: -1px;
    width: 0px;
    height: 0px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #000;
    transform: skewX(30deg) rotate(-15deg);
    -webkit-transform: skewX(30deg) rotate(-15deg);
    -moz-transform: skewX(30deg) rotate(-15deg);
    -ms-transform: skewX(30deg) rotate(-15deg);
    content: '';
    position: absolute;
}
.storm-desc-l {
    left:-197px;
    border-right: 5px solid #000;
}
.storm-desc-r {
    border-left: 5px solid #000;
    left:15px;
}
.storm-desc-r.th-timepin:before
{
    margin-left: -19px;
    left:0;
    top: 12px;
    transform: skewX(30deg) rotate(-195deg);
    -webkit-transform: skewX(30deg) rotate(-195deg);
    -moz-transform: skewX(30deg) rotate(-195deg);
    -ms-transform: skewX(30deg) rotate(-195deg);
}
.active.th-timepin:before {
    border-bottom-color:#ee4e22;
}
.active.th-timepin {
    border-color:#ee4e22;
}
.th-day, .th-hours {
    background: rgba(35,35,35,0.8);
    display: inline-block;
    height: 32px;
    line-height: 32px;
    font-size: 16px;
    text-align:center;
    white-space:nowrap;
    overflow:hidden;
}
.th-day {
    margin-right:1px;
    width:50px;
}
.th-hours {
    width:124px;
    padding-right:32px;
    box-sizing:border-box;
    /*background: rgba(35,35,35,0.8) url(../images/close_btn.png) no-repeat 101px 9px;*/
    background: rgba(35,35,35,0.8) no-repeat 101px 9px;
}
.active.th-timepin .th-hours {
    background-position: 101px -59px;
}
.history-timepin.active {
    box-shadow: 0 0 1px 8px #2d70ac;
}
