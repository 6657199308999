.wrapper{
  display: flex;
  cursor: pointer;
  height: 56px;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--palette-grey-100);

  .title{
    font-size: 16px;
    font-weight: 500;
  }

  .button{
    width: 32px;
    height: 32px;
  }
}