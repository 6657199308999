.wrapper{
  display:flex;
  flex-direction:row;
  padding:4px 8px;
  height:48px;
  gap:4px;
  background: white;
  border-radius:8px;
  border: 1px solid var(--palette-grey-100);

  .labelWrapper{
    display:flex;
    flex-direction:row;
    align-items:center;
    color: var(--palette-grey-900);
    gap:8px;

    .label{
      font-weight:500;
      font-size:14px;
    }
  }

  .buttonsWrapper{
    display:flex;
    flex-direction:row;
    gap:8px;
    align-items:center;

    .groupLabel{
      margin-right:8px;
      font-weight:400;
      font-size:14px;
      color: var(--palette-grey-500);
    }
  }
}