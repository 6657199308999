.dawnGradient {
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #075F90 0%, #FFBAB2 100%);
}

.leftGradient {
    width: 100%;
    height: 100%;
    background: linear-gradient(
            82deg,
            #f0f2f5 0%,
            #f0f2f5 61.61%,
            rgba(0, 0, 0, 0) 61.7%);
    background: -moz-linear-gradient(
            -352deg,
            #f0f2f5 0%,
            #f0f2f5 61.4%,
            rgba(0, 0, 0, 0) 61.7%);

    image-rendering: auto;
}

.cloud_0 {
    position: absolute;
    top: 6.79%;
    width: 82px;
    height: 52px;
    mix-blend-mode: overlay;
    transform: translateZ(0);
    animation: moveCloud_0 10s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

@keyframes moveCloud_0 {
    0%, 5% {
        left: 91.59%;
    }
    45%, 55% {
        left: 90%;
    }
    95%, 100% {
        left: 91.59%;
    }
}

.cloudMoonArea {
    width: 336px;
    height: 336px;
    position: absolute;
    left: 72%;
    top: 4.51%;
}

.moon {
    position: relative;
    left: 23.2%;
    top: 23.46%;
    width: 54%;
    height: 54%;
}

.cloud_1 {
    width: 53%;
    height: 53%;
    position: relative;
    top: -12%;
    transform: translateZ(0);
    animation: moveCloud_1 10s cubic-bezier(0.6, 0, 0.4, 1) infinite;
}

@keyframes moveCloud_1 {
    0%, 5% {
        left: 11.7%;
    }
    45%, 55% {
        left: 40.8%;
    }
    95%, 100% {
        left: 11.7%;
    }
}

.cloud_2 {
    position: absolute;
    top: 66.13%;
    width: 111px;
    height: 70px;
    mix-blend-mode: overlay;
    transform: translateZ(0);
    animation: moveCloud_2 10s cubic-bezier(0.8, 0, 0.2, 1) infinite;
}

@keyframes moveCloud_2 {
    0%, 5% {
        left: 69.71%;
    }
    45%, 55% {
        left: 65.71%;
    }
    95%, 100% {
        left: 69.71%;
    }
}

.cloud_3 {
    position: absolute;
    top: 79.2%;
    width: 145px;
    height: 92px;
    mix-blend-mode: overlay;
    transform: translateZ(0);
    animation: moveCloud_3 10s cubic-bezier(0.75, 0, 0.25, 1) infinite;
}

@keyframes moveCloud_3 {
    0%, 5% {
        left: 85.62%;
    }
    45%, 55% {
        left: calc(100% - 180px);
    }
    95%, 100% {
        left: 85.62%;
    }
}

.leftContainer {
    width: 61.67%;
    height: 100%;
    display: grid;
}

.alreadySignedUpContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.alreadySignedUpSection {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    flex-direction: column;
    height: 184px;
}

.forgotPasswordContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 24px;
}

@media screen and (width < 996px) {
    .loginFormContainer {
        height: 601px
    }
    .leftContainer {
        width: 100%;
    }
}