html {
    -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
    overflow: hidden;
}

.group:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

textarea, input, .DraftEditor-root span {
    -webkit-touch-callout: text !important;
    -webkit-user-select: text !important;
    -khtml-user-select: text !important;
    -moz-user-select: text !important;
    -ms-user-select: text !important;
    user-select: text !important;
}

* {
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

html, body, .wrapper {
    height: 100%;
    width: 100%;
}

.wrapper {
    float: left;
    width: 100%;
    overflow: auto;
    position: relative;
    overflow: hidden;
}

#map {
    width: 100%;
    height: 100%;
}

/* from default theme style.css */

body ins {
    background: none;
}

.nhctimelabel {
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 5px 10px;
    cursor: pointer;
}

.nhctimelabel.model::after,
.nhctimelabel.nhc::after {
    content: '+';
    display: inline-block;
    width: 20px;
    text-align: center;
}

.nhctimelabel.model.active::after {
    content: '-';
    display: inline-block;
    width: 20px;
    text-align: center;
}

.nhctimelabel.model.maplibregl-marker-anchor-left,
.nhctimelabel.model.mapboxgl-marker-anchor-left {
    border-left: 5px solid rgb(255, 171, 16);
}

.nhctimelabel.model.maplibregl-marker-anchor-right,
.nhctimelabel.model.mapboxgl-marker-anchor-right {
    border-right: 5px solid rgb(255, 171, 16);
}

.nhctimelabel.nhc.maplibregl-marker-anchor-left,
.nhctimelabel.nhc.mapboxgl-marker-anchor-left {
    border-left: 5px solid #2D71AD;
}

.nhctimelabel.nhc.maplibregl-marker-anchor-right,
.nhctimelabel.nhc.mapboxgl-marker-anchor-right {
    border-right: 5px solid #2D71AD;
}

.nhctimelabel span:last-child {
    width: 20px;
    text-align: center;
}

.mapboxgl-map .mapboxgl-ctrl-bottom-right {
    pointer-events: all;
}

.mapboxgl-ctrl-group, .maplibregl-ctrl-group{
    display: flex;
    flex-direction: column;
    gap: 4px;
    background: none;
    border: none;
    box-shadow: unset !important;
    margin: 0 24px 12px 12px !important;
}

.mapboxgl-ctrl-group button, .maplibregl-ctrl-group button{
    border-radius: 8px;
    border: 1px solid var(--palette-grey-100);
    background-color: white;
    height: 32px;
    width: 32px;
}

.mapboxgl-ctrl-group button:hover, .maplibregl-ctrl-group button:hover{
    background-color: var(--palette-grey-100)!important;
}

.mapboxgl-ctrl-group button:focus, .maplibregl-ctrl-group button:focus{
    border-radius: 8px!important;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon,
.maplibregl-ctrl button.maplibregl-ctrl-zoom-in .maplibregl-ctrl-icon{
    background: url('../../../../shared/assets/svg/plus.svg') no-repeat center center;
    background-size: 12px;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon,
.maplibregl-ctrl button.maplibregl-ctrl-zoom-out .maplibregl-ctrl-icon{
    background: url('../../../../shared/assets/svg/minus.svg') no-repeat center center;
    background-size: 12px;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-fullscreen .mapboxgl-ctrl-icon,
.maplibregl-ctrl button.maplibregl-ctrl-fullscreen .maplibregl-ctrl-icon {
    background: url('../../../../shared/assets/svg/zoomOutIcon.svg') no-repeat center center;
    background-size: 18px;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-shrink .mapboxgl-ctrl-icon,
.maplibregl-ctrl button.maplibregl-ctrl-shrink .maplibregl-ctrl-icon{
    background: url('../../../../shared/assets/svg/zoomIcon.svg') no-repeat center center;
    background-size: 18px;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl,
.maplibregl-ctrl-top-right .maplibregl-ctrl{
    margin: 15px 24px 0 0 !important;
}

.mapboxgl-map .mapboxgl-ctrl-attrib {
    display: none;
}

*:focus {
    box-shadow: 0 0 6px var(--semitransparentheavycolor) inset;
}

.DraftEditor-root span {
    word-spacing: 6px;
}