.wrapper{
  height: 100%;

  .headerWrapper{
    border-bottom: 1px solid var(--palette-grey-100);
  }

  .weatherDefinitionList{
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: auto;
    gap: 16px;
    padding: 16px 24px;
  }
}