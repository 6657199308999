.gridGroup {
    width: 100%;
    gap: 16px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    padding: 16px;
    background-color: white;
    border-radius: 12px;
}

.alertPopup {
    width: 656px;
    background: white;
    border-radius: 12px;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
}

.alertPopup .weatherDefinitionCard {
    border-radius: 8px;
    border: 1px solid;
    border-color: var(--palette-grey-100);
    padding: 10px 10px 10px 12px;
    background: var(--palette-grey-background);
}

.alertGridItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: max-content;
    padding: 16px;
    gap: 8px;
    cursor: pointer;
    border-radius: 12px;
    box-shadow: var(--palette-shadow-small);
}

.alertGridItem:hover,
.alertListItem:hover {
    box-shadow: var(--palette-shadow-middle);
}

.alertListItem .field {
    width: 232px;
}

.alertGridItem .fieldLabel,
.alertListItem .fieldLabel,
.alertPopup .fieldLabel {
    color: var(--palette-grey-700);
    flex-grow: 1;
}

.alertGridItem .fieldText,
.alertListItem .fieldText,
.alertPopup .fieldText {
    color: var(--palette-grey-900);
}

.alertImage {
    width: 144px;
    height: 144px;
    display: grid;
    align-items: center;
    justify-items: center;
}