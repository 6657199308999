.wrapper{
  display: flex;
  gap: 24px !important;
  padding: 24px 24px 32px 24px;

  .contentWrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: start;

    .content{
      display: flex;
      flex-direction: row;
      gap: 16px;
      border-bottom: 1px solid var(--palette-grey-100);
      padding: 8px;
      width: 1168px;

      .contentItem{
        color: var(--palette-grey-500);
        width: 268px;
        height: 24px;
      }
    }
  }
}