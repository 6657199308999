.customSettingsMenuButton {
  &.settingsMenuButton {
    width: 100%;
    background: none;
    font-size: 14px;
    display: flex;
    justify-content: start;
    align-content: center;
    height: 48px;
    color: var(--palette-grey-900);
    padding: 16px;

    &:hover {
      border-color: transparent;
    }

    &.selected {
      background: var(--palette-grey-background);
      color: var(--palette-primary-dark);

      & :global(.MuiSvgIcon-root) {
        color: var(--palette-primary-dark);
      }

      &:hover {
        background: var(--palette-grey-background);
      }
    }
  }
}