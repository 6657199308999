.wrapper{
  width: 432px;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .header{
    display: flex;
    flex-direction: column;

    .labelWrapper{
      display: flex;
      align-items: center;
      gap: 8px;
      height: 24px;

      .label{
        font-weight: 500;
        font-size: 16px;
        word-break: break-all;
      }
    }

    .address{
      font-weight: 400;
      font-size: 14px;
      word-break: break-all;
      color: var(--palette-grey-900);
    }
  }

  .group{
    width: fit-content;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    word-break: break-all;
    color: var(--palette-grey-900);
    background-color: var(--palette-grey-100);
    border-radius: 8px;
  }
}