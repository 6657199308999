.timeline-alert-popup {
    width: 400px;
    position: absolute;
    background: white;
    box-shadow: 3px 3px 12px rgba(103, 122, 142, 0.24);
    border-radius: 12px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}