.timeline {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 48px;
    width: 100%;

}

.timeline .timelineplaybtn {
    width: 32px;
    background-color: var(--palette-primary-dark);
    cursor: pointer;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: white;
    font-size: 10px;
    transition: 0.2s;
}
.timelineplaybtn svg{
    width: 14px;
    height: 14px;
    fill: white;
}

.timeline .timelineplaybtn:hover {
    background-color: var(--palette-primary-middle);
}

.timeline .timelinewrapperline {
    position: relative;
    background: white;
    width: 100%;
    height: 48px;
    vertical-align: top;
    border-radius: 8px;
    padding: 2px;
}

.timeline input[type=range].timelinecursor, .compactslider {
    position: absolute;
    width: 99.7%;
    z-index: 2;
    opacity: 0.6;
    background: transparent;
    height: 44px;
    border: none;
    padding: 0;
    border-radius: 8px;
    overflow: hidden;
}

.timelinewrapperline .timelinetime {
    width: 99.7%;
    height: 44px;
    line-height: 44px;
}

.timeline {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 3;
}

.futureorpast {
    height: 44px;
    position: absolute;
    width: 99.7%;
    border-radius: 8px;
    overflow: hidden;
}

.timelinetime {
    line-height: 35px;
    text-align: center;
    color: var(--textcolor);
    vertical-align: top;
    position: absolute;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.timelinetime.timelinesimpletime {
    width: 100%;
    background: rgba(225, 225, 225, 0.9);
}

.timeline input[type=range], .compactslider {
    -webkit-appearance: none;
    width: 100%;
    margin: 0px 0;
}

.timeline input[type=range]:focus, .compactslider:focus {
    outline: none;
}

.timeline input[type=range]::-webkit-slider-runnable-track, .compactslider::-webkit-slider-runnable-track {
    width: 100%;
    height: 48px;
    cursor: pointer;
    background: transparent;
}

.timeline input[type=range]::-webkit-slider-thumb, .compactslider::-webkit-slider-thumb {
    height: 48px;
    width: 10px;
    background: var(--basecolor);
    cursor: pointer;
    -webkit-appearance: none;
}

/*.timeline input[type=range]:focus::-webkit-slider-runnable-track, .compactslider:focus::-webkit-slider-runnable-track {*/
/*    background: rgba(152, 152, 152, 0.28);*/
/*}*/

.timeline input[type=range]::-moz-range-track, .compactslider::-moz-range-track {
    width: 100%;
    height: 48px;
    cursor: pointer;
    background: rgba(27, 27, 27, 0.28);
}

.timeline input[type=range]::-moz-range-thumb, .compactslider::-moz-range-thumb {
    height: 48px;
    width: 10px;
    background: var(--basecolor);
    cursor: pointer;
    border-radius: 0;
    border:none;
}

.timeline input[type=range]::-ms-track, .compactslider::-ms-track {
    width: 100%;
    height: 48px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

.timeline input[type=range]::-ms-fill-lower, .compactslider::-ms-fill-lower {
    background: rgba(0, 0, 0, 0.28);
}

.timeline input[type=range]::-ms-fill-upper, .compactslider::-ms-fill-upper {
    background: rgba(27, 27, 27, 0.28);
}

.timeline input[type=range]::-ms-thumb, .compactslider::-ms-thumb {
    width: 10px;
    background: var(--basecolor);
    cursor: pointer;
    height: 48px;
}

.timeline input[type=range]:focus::-ms-fill-lower, .compactslider:focus::-ms-fill-lower {
    background: rgba(27, 27, 27, 0.28);
}

.timeline input[type=range]:focus::-ms-fill-upper, .compactslider:focus::-ms-fill-upper {
    background: rgba(152, 152, 152, 0.28);
}

.timelinehided{
    display: none;
}

.timelinespeed{
    width: 20px;
    height: 48px;
    vertical-align: top;
    box-sizing: border-box;
    background: white;
    white-space: initial;
    border-radius: 4px;
}
.timelinespeed div{
    width: 16px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    color: white;
    background: var(--palette-primary-dark);
    cursor: pointer;
    margin: 2.5px 2px 3px;
    border-radius: 4px;
    transition: 0.2s;
}
.timelinespeed div.disabled{
    background: var(--palette-primary-light);
    cursor: not-allowed;
}

.timelinespeed div:hover:not(.disabled){
    background-color: var(--palette-primary-middle);
}

.timelinespeedvalue{
    width: 5px;
    background: var(--basecolor);
    position: absolute;
    bottom: 0;
}

.timelinewrapperline .timelinenowbtn {
    background: var(--basecolor) !important;
    height: 48px;
    width: 70px;
    font-size: 14px;
    cursor: pointer;
    margin: 0;
    line-height: 48px;
    color: var(--textcolor);
    padding: 0 6px;
    text-align: center;
    float: right;
    z-index: 4;
    border: 1px solid black;   
}
.timelineplaybtn .cls-1{
    fill: var(--basecolor);
}
