.header{
  border-bottom: 1px solid var(--palette-grey-100);
}

.newAlerts{
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--palette-grey-500);
  padding: 24px 0 0 24px;
}

.allAlerts{
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--palette-grey-500);
  padding: 24px 0 0 24px;
}

.deleteModalWrapper{
  align-content: stretch;
  overflow: hidden;
  &.MuiBox-root{
    width: 100%;
  }

  .deleteModalList{
    max-height: 120px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: auto;
    gap: 0 16px;
  }
}