.wrapper{
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  flex-direction: row;

  .buttonsWrapper{
    width: 288px;
    min-width: 288px;
    max-width: 288px;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 8px 0;
    justify-content: flex-start;
    display: flex;
  }
  .hiddenButtons{
    display: none;
  }

  .contentWrapper{
    height: 100%;
    flex-grow: 1;
    background: #ffffff;
    box-shadow: 4px 0 14px rgba(103, 122, 142, 0.12);
    border-radius: 16px 16px 0 16px;
  }
}

.hidden{
  display: none;
}