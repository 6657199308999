.newWeatherAlertsWrapper{
    display: block;
    flex-grow: 1;
    height: 100%;
}
.headerNWA{
    width: 100%;
    height: 65px;
    display: flex;
    border-bottom: 2px solid gainsboro;
    padding: 10px 0;
    justify-content: center;
}
.bodyNWA{
    display: flex;
    height: 100%;
}
.LocationMapSelector {
    flex-grow: 1;
}
.LocationMapSelector .row + div {
    xflex-grow: 1;
}
