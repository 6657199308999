* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


:root {
    --palette-common-white: white;
    --palette-common-black: black;
    --palette-common-vividRed: #E64610;
    --palette-common-electricGreen: #92DA1A;
    --palette-common-lightGreen: #97E9AB;
    --palette-common-darkBlue: #0D2B54;
    --palette-common-violet: #6D008C;
    --palette-common-pink: #FBEDFF;

    --palette-other-tooltip: #2D343D;

    --palette-action-disabledBackground: var(--palette-grey-100);
    --palette-action-disabled: #AFB8C0;

    --palette-controls-resting: #E3E7EB;
    --palette-controls-disabled: #EEF1F4;

    --palette-text-primary: #0F2744;
    --palette-text-secondary: #728095;

    --palette-primaryRed-light: #E81830;
    --palette-primaryRed-middle: #DA1A32;
    --palette-primaryRed-main: #921221;
    --palette-primaryRed-dark: #510D15;

    --palette-primary-light: #D8F3FF;
    --palette-primary-middle: #50B2DF;
    --palette-primary-main: #0496D9;
    --palette-primary-dark: #09638C;
    --palette-primary-dark-rgb: 9, 99, 140;

    --palette-secondary-light : #f3f9ff;
    --palette-secondary-middle : #9aabbb;
    --palette-secondary-main : #7f8f9f;
    --palette-secondary-dark : #728095;

    --palette-success-light : #E8F9F5;
    --palette-success-middle : #5CD8B8;
    --palette-success-main : #16C79A;
    --palette-success-dark : #0F8B6C;

    --palette-error-light: #FEEEEE;
    --palette-error-middle: #F58787;
    --palette-error-main: #F05454;
    --palette-error-dark: #A83B3B;

    --palette-info-light: #F8FAFF;
    --palette-info-middle: #689DFF;
    --palette-info-main: #2773FF;
    --palette-info-dark: #1B51B3;

    --palette-warning-light: #FFF9EE;
    --palette-warning-middle: #FFF3E8;
    --palette-warning-main: #FA963A;
    --palette-warning-dark: #E86F00;

    --palette-grey-background: #FAFBFE;
    --palette-grey-100: #F0F2F5;
    --palette-grey-200: #AFB8C0;
    --palette-grey-300: #94A3B2;
    --palette-grey-400: #7F8F9F;
    --palette-grey-500: #677A8E;
    --palette-grey-600: #5A6672;
    --palette-grey-700: #48515B;
    --palette-grey-800: #333C47;
    --palette-grey-900: #171E27;

    --palette-shadow-small: 0px 1px 3px 0px rgba(103, 122, 142, 0.24);
    --palette-shadow-middle: 0px 2px 6px 0px rgba(103, 122, 142, 0.24);
    --palette-shadow-large: 0px 4px 12px 0px rgba(103, 122, 142, 0.24);

    --palette-shadow-error-small: 0px 1px 3px 0px rgba(168, 59, 59, 0.24);

    --palette-urgency-Low: #0F8B6C;
    --palette-urgency-Medium: #E86F00;
    --palette-urgency-High: #A83B3B;

}

#root {
    width: 100%;
    height: 100%;
}

.app {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: #F0F2F5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    scrollbar-color: #94A3B2 var(--palette-grey-background);
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
}

.column {
    width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 12px;
    overflow: auto;
    height: max-content;
}

.grid {
    display: grid;
    align-items: center;
}

.spacer {
    flex-grow: 1;
}

h1 {
    color: var(--palette-grey-800);
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0.025em;
}

h2 {
    color: var(--palette-grey-900);
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.025em;
}

h3 {
    color: var(--palette-grey-800);
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0;
}

h4 {
    color: var(--palette-grey-900);
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
}

label {
    color: #333;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}

.subtitle {
    color: var(--palette-grey-800);
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.001em;
}

.paragraph {
    color: var(--palette-grey-800);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.025em;
}

.regular {
    font-weight: 400 !important;
}

.medium {
    font-weight: 500 !important;
}

.bold {
    font-weight: 600 !important;
}

.textEllipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
}

.white {
    color: white;
}

.overlap {
    grid-row: 1;
    grid-column: 1;
}

.fullWidth {
    width: 100% !important;
}

.fullHeight {
    height: 100%;
}

.gap0 {
    gap: 0;
}

.gap4 {
    gap: 4px;
}

.gap8 {
    gap: 8px;
}

.gap12 {
    gap: 12px;
}

.gap16 {
    gap: 16px;
}

.gap24 {
    gap: 24px;
}

.gap32 {
    gap: 32px;
}

.drawerButtonBox {
    width: 100%;
    display: flex;
    gap: 12px;
    background: white;
    justify-content: flex-end;
    padding: 12px 0 24px 0;
}

body {
    overflow-y: auto;
}


.container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px 24px;
    border-radius: 16px 0 0 16px;
    overflow: auto;
}

.toolbar {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    width: 100%;
    background: white;
    flex-wrap: wrap;
}

.toolbar.padding {
    padding: 16px 24px;
}

.toolbar h3,
.settings-toolbar h3 {
    color: var(--palette-grey-600);
}

.toolbar .MuiButton-root,
.settings-toolbar .MuiButton-root {
    font-weight: 400;
}

.settings {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-radius: 16px 0 0 16px;
    overflow: auto;
    height: 100%;
    width: 100%;
}
.settings-toolbar {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    width: 100%;
    background: white;
    flex-wrap: wrap;
    padding: 24px 20px 16px 20px;
}

.label {
    color: var(--palette-grey-700) !important;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.contact-line {
    width: 100%;
    height: 48px;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid;
    border-color: var(--palette-grey-100);
    background: white;
    padding: 12px 16px 12px 12px;
}

.recipientList {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    max-height: 330px;
}

.customSvg {
  fill: var(--palette-grey-500);
  vertical-align: middle;
  max-height: 16px;
  max-width: 16px;
}

.field {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 8px 0 12px;
    border-radius: 8px;
    border: 1px solid var(--palette-grey-100);
}

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background-color: var(--palette-grey-background);
  border-radius: 2px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--palette-grey-300);
  border-radius: 6px;
  background-clip: content-box;
  border: 4px solid transparent;
}