.titleWrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title{
    font-weight: 500;
    font-size: 16px;
  }
}

.mainContentWrapper{
  display: flex;
  flex-direction: column;
  gap: 8px;

  .categoryWrapper{
    display: flex;
    align-items: center;
    gap: 16px;

    .nameOfCategoryWrapper{
      border-radius: 12px;
      display: flex;
      align-items: center;

      .nameOfCategory{
        font-size: 14px;
        color: var(--palette-grey-700);
        background-color: var(--palette-grey-100);
        border-radius: 8px;
        align-items: center;
        padding: 4px 8px;
        display: flex;
        gap: 3px;
      }
    }
  }

  .wdName{
    font-weight: 400;
    font-size: 14px;
    color: var(--palette-grey-900);
  }
}

.descriptionWrapper{
  display: flex;
  flex-direction: column;
  gap: 8px;

  .titleOfWdDescription{
    font-weight: 400;
    font-size: 14px;
    color: var(--palette-grey-600);
  }

  .bodyOfWdDescription{
    font-weight: 400;
    font-size: 14px;
    color: var(--palette-grey-900);
  }
}

