.react-tel-input {
    grid-row: 1;
    grid-column: 1;
}

.react-tel-input .flag {
    width: 0;
    height: 0;
    background: none;
}

.react-tel-input .selected-flag {
    outline: none;
    width: 0;
    height: 0;
    padding: 0;
    border: none;
}

.react-tel-input .form-control {
    border-color: transparent;
    box-shadow: var(--palette-shadow-small);
    height: 40px;
    width: 100%;
    padding: 10px 10px 10px 12px;
    color: var(--palette-grey-900);
    border-radius: 8px;
}

.react-tel-input .form-control[disabled] {
    cursor: text;
}

.react-tel-input .form-control::placeholder {
    color: var(--palette-grey-400);
    opacity: 1;
}

.react-tel-input .form-control:hover {
    border-color: var(--palette-primary-dark);
    box-shadow: none;
}

.react-tel-input .form-control:focus {
    border-color: var(--palette-primary-dark);
    box-shadow: none;
}

.react-tel-input .form-control.invalid-number {
    border: 1px solid var(--palette-error-main);
}

.react-tel-input .form-control.invalid-number:focus {
    box-shadow: var(--palette-shadow-error-small);
}

.react-tel-input.startAdornment .form-control {
    padding-left: 46px;
}