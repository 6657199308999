.wrapper{
  background: white;
  padding: 4px 10px 4px 12px;
  border: 1px solid;
  border-color: var(--palette-grey-100);
  border-radius: 12px;
  min-height: 32px;
  flex-wrap: wrap;
  min-width: 380px;

  .iconWrapper{
    max-height: 16px;
    max-width: 16px;
  }
}