.wrapper{
  width: 472px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    line-height: 32px;

    .iconWrapper{
      height: 32px;
      padding: 8px;

      .icon{
        width: 16px;
        height: 16px;
      }
    }

    .title{
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .contentWrapper{
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;

    .content{
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: var(--palette-grey-600);
    }
  }

  .buttonsWrapper{
    display: flex;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    height: 40px;

    .button{
      width: fit-content;
      font-weight: 400;
    }
  }
}