.productsVisible{
    height: 180px;
    border: 1px solid #F0F2F5;
    gap: 2px;
    margin-top: 5px;
    opacity: 1;
    transition: 0.25s;
    padding: 0 5px 5px 5px;
    min-height: 100px;
}

.productsInvisible{
    height: 0;
    border: 0;
    gap: 0;
    margin: 0;
    opacity: 0;
    transition: 0.25s;
    padding: 0;
}