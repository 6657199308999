.definitionPreview {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    flex-grow: 1;
    min-width: 350px;
    min-height: 224px;
    border-radius: 8px;
}

.definitionGrid {
    display: grid;
    align-items: center;
    gap: 8px;
    width: 100%;
    grid-template-columns: 1fr auto max-content;
    padding: 16px;
    backdrop-filter: blur(37px);
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.10);
}

.definitionGrid .line {
    grid-column: 1 / -1;
    height: 1px;
    background: rgba(255, 255, 255, 0.20);
}

.definitionGrid .line:last-child {
    background: transparent;
}

.definitionGrid .MuiTypography-root {
    color: white;
}

.definitionGrid .MuiChip-root {
    background: rgba(240, 242, 245, 0.15);
    color: white;
}

.definitionGrid .braces {
    font-size: 20px !important;
    font-weight: 300 !important;
}

.definitionGrid .union {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.definitionGrid .productIcon {
    fill: white;
    max-height: 16px;
    max-width: 16px;
    vertical-align: middle;
}
