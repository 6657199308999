.barContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.barBackground {
    background-color: var(--palette-grey-100);
    border-radius: 8px;
    border-width: 2px;
    height: 14px;
    width: 100%;
    padding: 2px;
}

.bar {
    height: 10px;
    background-color: var(--palette-primary-dark);
    border-radius: 8px;
}

.strengthText {
    margin-left: 5px;
    font-weight: 500;
    font-size: 12px;
    color: var(--palette-primary-dark);
    line-height: 16px;
}