.wrapper{
  overflow: hidden;
  flex-grow: 1;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: stretch;

  .header{
    border-bottom: 1px solid #dbe0ea;
    justify-content: space-between;

    .headerText{
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .completeButton{
      width: 95px;
    }
  }

  .contentWrapper{
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    padding: 20px;
    background-color: var(--palette-grey-100);
  }
}