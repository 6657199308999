.wrapper{
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 12px 4px 10px;
  background-color: var(--palette-grey-background);
  border-radius: 8px;
  border: 1px solid var(--palette-grey-100);
  font-weight: 400;
  font-size: 14px;
  color: var(--palette-grey-500);
}