.header{
  border-bottom: 1px solid var(--palette-grey-100);
  border-radius: 16px 0 0 0;
}

.contentWrapper{
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 24px;

  .parametersWrapper{
    padding: 16px;
    border-radius: 10px;
    border: 1px solid var(--palette-grey-100);
  }

  .configsWrapper{
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 8px;
    border-radius: 10px;
    border: 1px solid var(--palette-grey-100);
    background: var(--palette-grey-background);
  }
}

.title{
  color: var(--palette-grey-600) !important;
}

.deleteTitle{
  font-weight: 500;
  color: var(--palette-grey-900);
}

.locationWrapper{
  padding: 16px;
  background: #fff;
  line-height: 28px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .pinWrapper{
    font-size: 14px;
    color: var(--palette-grey-700);
    background-color: var(--palette-grey-100);
    border-radius: 8px;
    padding: 4px 10px;
    margin-right: 10px;

    .pin{
      vertical-align: middle;
      padding-bottom: 2px;
      width: 16px;
      margin-right: 5px;
    }
  }

  .iconWrapper{
    margin-left: auto;

    .icon{
      padding: 7px;
      background: #fff;
      border: solid 1px var(--palette-grey-100);
    }
  }
}
