.header{
  border-bottom: 1px solid var(--palette-grey-100);

  .toolsWrapper{
    display: flex;
    gap: 8px;
  }
}

.massDeleteModal{
  align-content: stretch;
  overflow: hidden;
  &.MuiBox-root{
    width: 100%
  };

  .deleteModalList{
    max-height: 120px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: auto;
    gap: 0 16px;
  }
}