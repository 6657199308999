
.EmptyStatesDefinitionPreviewTitle{
    padding-left: 16px;
}

.EmptyStatesDefinitionPreviewBackground{
    right: -220px;
    top: -7px;
    width: 651px;
    height: 651px;
}

.EmptyStatesDefinitionPreviewRectangle1{
    right: 1px;
    top: 6px;
}

.EmptyStatesDefinitionPreviewRectangleMain{
    right: 107px;
    bottom: 50px;
}

.EmptyStatesDefinitionPreviewRectangle2{
    right: 1px;
    bottom: -2px;
}

@media (min-width: 1025px) and (max-width: 1370px) {
    .EmptyStatesDefinitionPreviewTitle{
        padding-left: 0;
    }

    .EmptyStatesDefinitionPreviewRectangle1{
        right: -80px;
    }

    .EmptyStatesDefinitionPreviewRectangleMain{
        right: -10px;
    }

    .EmptyStatesDefinitionPreviewRectangle2{
        right: -80px;
    }
}

@media (max-width: 1024px) {
    .EmptyStatesDefinitionPreviewRectangle1{
        right: -135px;
    }

    .EmptyStatesDefinitionPreviewRectangleMain{
        right: -55px;
    }

    .EmptyStatesDefinitionPreviewRectangle2{
        right: -135px;
    }
}

@media (max-width: 965px) {
    .EmptyStatesDefinitionPreviewRectangleMain{
        right: -75px;
    }
}