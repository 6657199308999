.AlertsSettingsList{
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: auto;
  gap: 12px;
  padding: 16px 24px;
}

.removePadding{
  padding: 0;
}