.radio-group {
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  max-height: 0;

  .MuiFormGroup-root {
    padding-top: 12px;
  }
}

.radio-group.visible {
  max-height: 500px;
}

.marker {
  cursor: pointer;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.35));
  background: rgba(white, 0.4);
  border-radius: 50%;
  transition: background-color 0.2s ease-in-out;

  .marker-label {
    color: var(--palette-grey-900);
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    text-align: center;
    font-size: 10px;
    font-weight: 400;
  }
}

.marker.selected {
  background: rgba(var(--palette-primary-dark-rgb), 0.8);

  .marker-label {
    color: var(--palette-primary-dark);
    background: white;
  }
}

.marker.hidden {
    display: none;
    pointer-events: none;
}

.marker.error {
  background: var(--palette-error-dark);

  .marker-label {
    color: var(--palette-error-dark);
    background: white;
  }
}