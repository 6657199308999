.wrapper{
  width: 100%;
  height: 100%;

  .contentWrapper{
    width: 100%;
    padding: 0;

    .namePartWrapper{
      display: flex;
      flex-direction: column;
      gap: 4px;

      .errorWrapper{
        font-weight: 500;
        color: var(--palette-warning-dark);
        font-size: 14px;

        .errorMessage{
          display: flex;
          align-items: center;
          gap: 8px;

          .errorIcon{
            color: var(--palette-warning-dark);
          }
        }
      }
    }

    .pointPartWrapper{
      padding: 8px 8px 16px 8px;
      border: 1px solid;
      border-radius: 8px;
      border-color: var(--palette-grey-background);
      background-color: var(--palette-grey-background);
    }

    .locationGroupWrapper{
      width: 100%;
    }

    .footerWrapper{
      padding: 2px;
    }
  }

  .title{
    color: var(--palette-grey-500);
  }
}