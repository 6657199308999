.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 24px;

  .accordionSummary {
    width: auto;
    height: 46px;
    display: flex;
    align-items: center;
    background: var(--color-coding-grayscale-0, #FFF);
    border-radius: 8px;

    .content {
      display: flex;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 8px;

      .contentLength {
        color: var(--color-coding-grayscale-900, #171E27);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.16px;
      }
    }
  }

  .accordionDetails {
    display: flex;
    flex-direction: column;
    background: var(--palette-grey-background);
    padding: 0 16px;
  }

  .buttonsWrapper {
    display: flex;
    padding: 0 16px 16px;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    background: var(--palette-grey-background);
  }
}

.settingsToolbar{
  border-bottom: 1px solid var(--palette-grey-100);

  .toggleButton{
    height: 100%;
    padding: 10px;
  }
}

.mainContentWrapper{
  overflow: auto;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
}
