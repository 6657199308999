.wrapper{
  padding: 16px 8px 16px 16px;
  gap: 16px;
  background: #fff;
  border: 1px solid #F0F2F5;
  box-shadow: 0 4px 12px rgba(103, 122, 142, 0.24);
  border-radius: 12px;

  .header{
    display: flex;
    gap: 28px;
    align-items: center;

    .link{
      color: var(--palette-primary-dark);
      font-size: 14px;
      font-weight: 500;
      text-decoration: none;
    }
  }

  .contentWrapper{
    overflow-y: auto;
    width: 500px;
    max-height: 500px;
    gap: 8px;
    justify-content: flex-start;

    .content{
      padding-right: 8px;
      overflow: unset;

      .label{
        font-weight: 400;
        color: var(--palette-grey-500);
      }

      .contentBlock {
        color: var(--palette-grey-500);

        & b {
          color: var(--palette-grey-900);
        }

        .contentListWrapper {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .contentList {
            padding: 2px 16px;
            border: 1px solid var(--palette-primary-dark);
            border-radius: 8px;
            width: fit-content;

            .contentListItem {
              color: var(--palette-primary-dark);
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
}