.nightGradient {
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #2495D4 0%, #57248A 100%);
}


.rightGradient {
    width: 100%;
    height: 100%;
    background: linear-gradient(
            98deg,
            rgba(0, 0, 0, 0%) 0%,
            rgba(0, 0, 0, 0%) 38.39%,
            #f0f2f5 38.45%);
    background: -moz-linear-gradient(
            -368deg,
            rgba(0, 0, 0, 0%) 0%,
            rgba(0, 0, 0, 0%) 38.1%,
            #f0f2f5 38.4%);
}


.night_cloud_0 {
    position: absolute;
    top: 4.09%;
    width: 82px;
    height: 52px;
    mix-blend-mode: overlay;
    transform: translateZ(0);
    animation: moveNightCloud_0 10s cubic-bezier(0.75, 0, 0.25, 1) infinite;
}

@keyframes moveNightCloud_0 {
    0%, 5% {
        left: 27.22%
    }
    45%, 55% {
        left: 25.48%;
    }
    95%, 100% {
        left: 27.22%;
    }
}

.night_cloud_1 {
    position: absolute;
    top: 12.61%;
    width: 256px;
    height: 163px;
    mix-blend-mode: overlay;
    transform: translateZ(0);
    animation: moveNightCloud_1 10s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

@keyframes moveNightCloud_1 {
    0%, 5% {
        left: 4.58%
    }
    45%, 55% {
        left: 10.06%;
    }
    95%, 100% {
        left: 4.58%;
    }
}

.night_cloud_2 {
    position: absolute;
    top: 66.59%;
    width: 111px;
    height: 70px;
    mix-blend-mode: overlay;
    transform: translateZ(0);
    animation: moveNightCloud_2 10s cubic-bezier(0.8, 0, 0.2, 1) infinite;
}

@keyframes moveNightCloud_2 {
    0%, 5% {
        left: 3.43%
    }
    45%, 55% {
        left: 1.04%;
    }
    95%, 100% {
        left: 3.43%;
    }
}

.night_cloud_3 {
    position: absolute;
    top: 82.04%;
    width: 145px;
    height: 92px;
    mix-blend-mode: overlay;
    transform: translateZ(0);
    animation: moveNightCloud_3 10s cubic-bezier(0.75, 0, 0.25, 1) infinite;
}

@keyframes moveNightCloud_3 {
    0%, 5% {
        left: 16.73%
    }
    45%, 55% {
        left: 19.79%;
    }
    95%, 100% {
        left: 16.73%;
    }
}

.alreadyHaveAccountContainer {
    width: 38.39%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.alreadyHaveAccountSection {
    display: flex;
    justify-content: center;
    gap: 24px;
    align-items: center;
    text-align: center;
    flex-direction: column;
    height: 208px;
    color: white;
}

#termsOfService {
    color: #677A8E;
    font-size: 12px;
    line-height: 16px;
}

.newIndustryVisible {
    height: 40px;
    opacity: 1;
    transition: 0.35s;
}

.newIndustryInvisible {
    overflow: hidden;
    height: 0;
    gap:0;
    opacity: 0;
    transition: 0.35s;
}