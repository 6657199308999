.infoPages_wrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;
    overflow-y: auto;
}

.infoPages_header {
    display: flex;
    justify-content: space-between;
    flex-grow: unset;
    width: 1024px;
    margin: 0 auto;
    height: 60px;
    padding: 16px;
}

img {
    height: 34px;
}

.infoPages_button {
    width: fit-content;
    height: 34px;
}

.infoPages_content {
    padding: 40px;
    border: 1px solid white;
    border-radius: 12px;
    height: fit-content;
    background: white;
    width: 1024px;
    margin: 0 auto 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.infoPages_list{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.infoPages_line{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.infoPages_buttons_block{
    display: flex;
    align-items: center;
    gap: 10px;
}

.infoPages_success{
    color: var(--palette-success-main)
}

.infoPages_error{
    color: var(--palette-error-main);
}

.infoPages_full_info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
}