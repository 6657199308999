.wrapper{
  height: 100%;

  .header{
    border-bottom: 1px solid var(--palette-grey-100);
  }

  .mainWrapper{
    padding: 6px 24px;
    align-items: start;
    align-content: start;
    overflow-y: auto;
    height: 100%;
    flex-wrap: wrap;

    .main{
      display: flex;
      flex-wrap: wrap;
      height: fit-content;
      width: 100%;
      gap: 16px;

      .paper{
        padding: 24px;
        flex-grow: 1;
        min-width: 500px;
        min-height: 224px;
        height: auto;

        .select{
          width: 180px;

          .selectContentWrapper{
            display: flex;
            gap: 8px;
            align-items: center;

            .selectContent{
              width: 24px;
              height: 24px;
              justify-content: center;
              border: 1px solid var(--palette-grey-100);
              border-radius: 4px;
              background-color: var(--palette-grey-background);

              .selectContentIcon{
                fill: var(--palette-primary-dark);
              }
            }

            .iconName{
              font-size: 14px;
              font-weight: 400;
            }

            .selectedIconName{
              color: var(--palette-primary-dark);
            }

            .unSelectedIconName{
              color: var(--palette-grey-300);
            }
          }
        }
      }
    }
    .bottomPaper{
      padding: 24px;
      overflow-y: auto;
      height: auto;

      .containedPaper{
        padding: 0;
        background: var(--palette-grey-background);
      }
    }
  }
}