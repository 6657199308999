.NewAlertStepper {
    width: 288px;
    min-width: 288px;
    padding: 0 24px 24px 24px;
    background-color: var(--palette-grey-100);
    align-items: center;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.NewAlertStepper .MuiStep-root {
    background: var(--palette-grey-background);
    border-radius: 12px;
    cursor: pointer;
    width: 240px;
    height: max-content;
    padding: 16px;
    border: 1px solid transparent;
    box-shadow: none;
    transition: box-shadow 150ms ease-in-out;
}

.NewAlertStepper .MuiStep-root .MuiTypography-root {
    color: var(--palette-grey-500);
}

.NewAlertStepper .MuiStep-root.selected,
.NewAlertStepper .MuiStep-root.Mui-completed {
    background: white;
}

.NewAlertStepper .MuiStep-root.selected .title,
.NewAlertStepper .MuiStep-root.Mui-completed .title {
    color: var(--palette-grey-900);
}

.NewAlertStepper .MuiStep-root.selected .description,
.NewAlertStepper .MuiStep-root.Mui-completed .description {
    color: var(--palette-grey-800);
}

/* Styles for .stepCompleted within .MuiStep-root */
.NewAlertStepper .MuiStep-root .stepCompleted {
    opacity: 0;
    transition: opacity 150ms ease-in-out;
}

/* Styles for .stepCompleted within completed .MuiStep-root */
.NewAlertStepper .MuiStep-root.Mui-completed .stepCompleted {
    opacity: 100%;
}

.NewAlertStepper .MuiStep-root.selected {
    box-shadow: var(--palette-shadow-large);
}

.NewAlertStepper .MuiStep-root.selected .stepLabel .MuiTypography-root:first-child {
    color: var(--palette-primary-dark);
}

.NewAlertStepper .MuiStep-root .MuiDivider-root {
    margin: 8px 0 8px 0;
    height: inherit;
}

.NewAlertStepper .stepLabel {
    display: flex;
    justify-content: space-between;
    gap: 4px;
    width: 100%;
}

.NewAlertStepper .stepContent .description {
    margin-top: 4px;
}

.buttonWrapper{
    width: 100%;
    display: flex;
    justify-content: center;
}

@media (max-height: 815px) {
    .NewAlertStepper{
        overflow-y: auto;
    }
    .buttonWrapper{
        position: sticky;
        bottom: 0;
        padding: 14px 24px;
        background-color: white;
    }
}