.accordionSummary{
  gap: 4px !important;

  .title{
    color: var(--palette-grey-900);
  }
}

.accordionDetails{
  padding-left: 36px !important;
  padding-right: 14px !important;
}