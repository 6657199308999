.toolbarBorder{
  border-bottom: 1px solid var(--palette-grey-100);
}

.contentWrapper{
  padding: 16px 24px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: auto;

  .parametersWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;

    .parametersTitle{
      font-weight: 500;
      font-size: 12px;
      color: var(--palette-grey-600);
      text-transform: uppercase;
      line-height: 40px;
    }

    .iconButton{
      width: 40px;
      height: 40px;
      box-shadow: 0 1px 3px 0 #677A8E3D;
    }
  }
}