.alertsSettingsLine{
  display: flex;
  cursor: pointer;
  height: 56px;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--palette-grey-100);

  .circleIcon{
    color: var(--palette-primary-dark)
  }

  .titleWrapper{
    display: flex;
    align-items: center;
    gap: 16px;

    .title{
      font-size: 16px;
      font-weight: 500;
    }
  }

  .contentWrapper{
    margin-left: auto;
    line-height: 16px;
    display: flex;
    gap: 8px;
    height: 32px;
    align-items: center;

    .locationsLengthIcon{
      display: flex;
      width: 32px;
      height: 32px;
      border: 1px solid var(--palette-grey-100);
      border-radius: 8px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      color: var(--palette-primary-dark);
    }
  }

  .moreIcon{
    width: 32px;
    height: 32px;
  }
}

.alertsSettingsLineNew{
  background-color: var(--palette-info-light);
}

.locationsList{
  max-width: 300px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

