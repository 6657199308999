.labelColor{
  color: var(--palette-grey-900) !important;
}

.iconWrapper{
  width:160px;
  height:160px;
  border-radius:12px;
  background-color: var(--palette-common-lightGreen);
  display:flex;
  justify-content:center;
  align-items:center;
  margin: 0 auto;

  .iconContent{
    width: 130px;
    height: 130px;
    fill: white;
  }
}