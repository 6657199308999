.wrapper{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;

  .content{
    overflow: auto;
    height: 100%;

    .emptyWrapper{
      width: 100%;
      height: 100%;
    }

    .renderGroupsWrapper{
      width: 100%;
      padding: 16px 24px;
    }
  }
}