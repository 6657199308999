.notFoundContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: start;
}

.notFoundContainer .error_404 {
    font-size: 148px;
    color: white;
}

.notFoundContainer .title {
    color: var(--palette-grey-100);
    font-size: 56px;
}

.notFoundContainer .description {
    color: var(--palette-grey-100);
    font-size: 20px;
    width: 350px;
}